import { configureStore } from '@reduxjs/toolkit'
import userSlice from './reducers/userReducer'
import modelViewerSlice from './reducers/modelViewerReducer'
import requestSlice from './reducers/requestReducer'
import requestNotificationSlice from './reducers/requestNotificationReducer'
import orderNotificationSlice from './reducers/orderNotificationReducer'

export const store = configureStore({
    reducer: {
        user: userSlice,
        modelViewer: modelViewerSlice,
        request: requestSlice,
        requestNotification: requestNotificationSlice,
        orderNotification: orderNotificationSlice,
    },
})