import { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { changeColor } from '../../redux/reducers/modelViewerReducer';
import { axiosPrivate } from '../../api/api';

const ColorPicker = () => {
    const dispatch = useDispatch();
    const pickerRef = useRef(null);
    const { data } = useSelector((state) => state.modelViewer);
    const [color, setColor] = useState(null);
    const [showPicker, setShowPicker] = useState(false);
    const isColorChanged = useRef(false);

    useEffect(() => {
        setColor(data?.background_color)
    }, [data?.background_color])

    const updateModelFields = async (data, id) => {
        try {
            await axiosPrivate.patch(`/api/v1/models/${id}/`, data)
        } catch (error) {
            console.log(error);
        }
    }

    const buttonStyle = {
        cursor: "pointer",
        border: "none",
        outline: "none",
        width: "21px",
        height: "21px",
        borderRadius: "4px",
        background: color,
    };

    const handleClick = () => {
        setShowPicker(!showPicker);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false);
                if (isColorChanged.current) {
                    updateModelFields({ "background_color": color, }, data.id)
                    isColorChanged.current = false;
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [color, data.id, dispatch]);

    const handleChange = (newColor) => {
        setColor(newColor.hex);
        dispatch(changeColor({
            color: newColor.hex,
            id: data.id
        }));
        if (newColor.rgb.a !== 1) {
            setColor(
                `rgba(${newColor.rgb.r}, ${newColor.rgb.g}, ${newColor.rgb.b}, ${newColor.rgb.a})`
            );
            dispatch(
                changeColor({
                    color: `rgba(${newColor.rgb.r}, ${newColor.rgb.g}, ${newColor.rgb.b}, ${newColor.rgb.a})`,
                    id: data.id
                })
            );
        }

        isColorChanged.current = true;
    };

    return (
        <div ref={pickerRef}>
            <button onClick={handleClick} style={buttonStyle}></button>
            {showPicker && (
                <div
                    style={{
                        position: "absolute",
                        zIndex: "2",
                        right: "0",
                    }}
                >
                    <ChromePicker color={color} onChange={handleChange} />
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
