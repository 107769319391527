import { useState } from "react";

const useBase64 = () => {
    const [base64Array, setBase64Array] = useState([]);
    const [open, setOpen] = useState(false)

    const imageToBase64 = (urls) => {
        const urlToBase64 = async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }

        if (urls?.length > 0) {
            Promise.all(urls?.map(urlToBase64))
                .then(base64Strings => setBase64Array(base64Strings))
        }
    }

    const handleFileSelect = (event) => {
        setOpen(true)
        const files = event.target.files;
        const newBase64Array = base64Array;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                newBase64Array.push(reader.result);
                setBase64Array([...newBase64Array]);
            };
        }
    };

    const handleUpdate = (event, index) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const newBase64Array = [...base64Array];
            newBase64Array[index] = reader.result;
            setBase64Array(newBase64Array);
        };
    };

    const handleDelete = (index) => {
        setOpen(true);
        setBase64Array(base64Array.filter((_, i) => i !== index));
    };

    return { base64Array, handleFileSelect, handleUpdate, handleDelete, imageToBase64, open };
};

export default useBase64;
