import React, { useRef } from "react";
import "./Modal.scss"
import QRCode from "react-qr-code";

function CustomModal({ showModal, setShowModal, modelID }) {
    const qrcode = useRef()

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            setShowModal(false);
        }
    };

    const downloadQR = () => {
        const svgElement = qrcode.current;
        const canvasElement = document.createElement('canvas');
        canvasElement.width = svgElement.clientWidth;
        canvasElement.height = svgElement.clientHeight;

        const svgString = new XMLSerializer().serializeToString(svgElement);
        const ctx = canvasElement.getContext('2d');
        const DOMURL = window.URL || window.webkitURL || window;
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
        const url = DOMURL.createObjectURL(svgBlob);
        const img = new Image();
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            DOMURL.revokeObjectURL(url);

            const downloadLink = document.createElement('a');
            downloadLink.download = 'qrcode.png';
            downloadLink.href = canvasElement.toDataURL();
            downloadLink.click();
        };
        img.src = url;
    };

    return (
        <>
            {showModal && (
                <div className="overlay" onClick={handleOverlayClick}>
                    <div className="modal">
                        <div className="modal-content">
                            <div className="qr-code-wrapper">
                                <h3>Scan QR Code</h3>

                                <div className='qrcode'>
                                    <QRCode
                                        ref={qrcode}
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={`${process.env.REACT_APP_SITE_URL}model/viewer/${modelID}`}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>

                                <button className='download-qr' onClick={downloadQR}>Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CustomModal;
