import { createAsyncThunk, current } from '@reduxjs/toolkit'
import { axiosPrivate } from '../../api/api';

const updateModelFields = async (data, id) => {
    try {
        await axiosPrivate.patch(`/api/v1/models/${id}/`, data)
    } catch (error) {
        console.log(error);
    }
}

const getModelViewerData = createAsyncThunk('modelViewer/ferchModel', async (id, { rejectWithValue }) => {
    try {
        const result = await axiosPrivate.get(`/api/v1/models/${id}/`)
        return result.data

    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

const changeBgColor = (state, { payload }) => {
    console.log(payload);
    state.data.background_color = payload.color
    // if (payload.change) {
    //     updateModelFields({ "background_color": payload.color, }, payload.id)
    // }
}

const handleAutoRotate = (state, { payload }) => {
    state.data.is_autorotate = payload.is_autorotate
    updateModelFields({ "is_autorotate": payload.is_autorotate, }, payload.id)
}

const handleAutoLoad = (state, { payload }) => {
    updateModelFields({ "is_autoload": payload.is_autoload, }, payload.id)
}

const changeAddNewStatus = (state, { payload }) => {
    state.modelAnnations.addNew = payload
}

const changeShowInputStatus = (state, { payload }) => {
    state.modelAnnations.showInput = payload
}

const changeAnnotations = (state, { payload }) => {
    state.data.annotations.push(payload);
}

const changeAnnotationText = (state, { payload }) => {
    const { annotations } = current(state).data;

    const newAnnotationList = annotations.map(annotation => {
        if (annotation.id === payload.id) {
            return { ...annotation, text: payload.text };
        }
        return annotation;
    });

    const updateAnnotationTextServer = async (id, message) => {
        try {
            await axiosPrivate.patch(`/api/v1/models/annotation/${id}/`, message)
        } catch (error) {
            console.log(error);
        }
    }

    updateAnnotationTextServer(payload.id, { text: payload.text })
    state.data.annotations = newAnnotationList
}

const deleteAnnotationItem = (state, { payload }) => {
    const { annotations } = current(state).data;

    const newAnnotationList = annotations.filter(annotation => {
        if (annotation.id !== payload) {
            return annotation
        }
        return false;
    });

    const deleteBack = async (id) => {
        await axiosPrivate.delete(`/api/v1/models/annotation/${id}/`)
    }

    deleteBack(payload)
    state.data.annotations = newAnnotationList
}

const deleteAnnotationLastItem = (state) => {
    const { annotations } = current(state).data;
    const newArr = [...annotations];
    newArr.pop()
    state.data.annotations = newArr
}

const updateVariantItem = (state, { payload: { name, id, status } }) => {
    const { variants } = state.data;
    const newVariants = variants.map(variant => {
        if (variant.name !== name) return variant;
        const options = variant.options.map(option => {
            if (option.id !== id) return option;
            return { ...option, is_active: status };
        });
        return { ...variant, options };
    });

    const updateVariantOptionStatus = async (id, is_active) => {
        try {
            await axiosPrivate.patch(`/api/v1/models/variant/${id}/`, {
                "is_active": is_active
            })
        } catch (error) {
            console.log(error);
        }
    }

    updateVariantOptionStatus(id, status)

    return { ...state, data: { ...state.data, variants: newVariants } };
};


const changeEnvironment = (state, { payload }) => {
    state.data.environment = payload.environment;
    updateModelFields({ "environment": payload.environment, }, payload.id)
}

export {
    getModelViewerData,
    changeBgColor,
    handleAutoRotate,
    handleAutoLoad,
    changeAddNewStatus,
    changeShowInputStatus,
    changeAnnotations,
    changeAnnotationText,
    deleteAnnotationItem,
    deleteAnnotationLastItem,
    updateVariantItem,
    changeEnvironment
}