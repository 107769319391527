import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ModelConfiguratorEditor from '../../components/ModelConfiguratorEditor/ModelConfiguratorEditor'
import ModelDetailsBreadcrumb from '../../components/ModelDetailsBreadcrumb/ModelDetailsBreadcrumb'
import ModelActions from '../../components/ModelActions/ModelActions'
import ModelViewerConfigurator from '../../components/ModelViewerConfigurator/ModelViewerConfigurator'
import ModelDetailsReady from '../../components/ModelDetails/ModelDetailsReady'
import { useDispatch, useSelector } from 'react-redux'
import { getModelViewerData } from '../../redux/actions/modelViewer'
import { addNew } from '../../redux/reducers/modelViewerReducer'
import { axiosPrivate } from '../../api/api'
import { io } from 'socket.io-client';

const MyProductsDetails = () => {
    const product_id = useParams().id
    const dispatch = useDispatch()
    const { data, modelAnnations } = useSelector((state) => state.modelViewer);
    const [url, setUrl] = useState(window.location.href);
    const [modelAnalytics, setModelAnalytics] = useState(null);
    const [modelLiveViews, setmodelLiveViews] = useState(0);
    const ModelDetailsBreadcrumbs = [
        {
            name: 'Home',
            url: '/'
        },
        {
            name: 'My products',
            url: '/my-products'
        },
        {
            name: `${data?.sku}`,
            url: ''
        },
    ]

    useEffect(() => {
        dispatch(getModelViewerData(product_id))
    }, [dispatch, product_id])

    // * Fetch Model Analytics
    useEffect(() => {
        const getAnalytics = async id => {
            try {
                const result = await axiosPrivate.get(`/api/v1/analytics/models/${id}/`)
                setModelAnalytics(result.data);
            } catch (error) {
                console.log(error);
            }
        }
        getAnalytics(product_id)
    }, [product_id])

    useEffect(() => {
        const socket = io.connect(process.env.REACT_APP_ANALYTICS_URL, { query: `uuid=${product_id}` });
        socket.on(product_id, live_views => {
            setmodelLiveViews(live_views)
        })
        return () => {
            socket.disconnect()
        };
    }, [product_id]);

    // * Detect Route Path Changes 
    useEffect(() => {
        const handleClick = () => {
            if (modelAnnations.addNew) {
                requestAnimationFrame(() => {
                    if (url !== window.location.href) {
                        dispatch(addNew(false))
                        setUrl(window.location.href);
                    }
                });
            }
        };

        document.body.addEventListener('click', handleClick, true);

        return () => {
            document.body.removeEventListener('click', handleClick, true);
        };
    }, [dispatch, modelAnnations.addNew, url]);

    return (
        <div className='order-wrapper'>
            <ModelDetailsBreadcrumb children={ModelDetailsBreadcrumbs} />
            <div className='order-details-wrapper'>
                <div className='model-side'>
                    <div className='model-block'>
                        <ModelViewerConfigurator />
                    </div>
                    <ModelActions name={data?.name} active={true} model_url={data?.file} scriptMessage={data?.script} frameMessage={data?.iframe} />
                    <ModelDetailsReady iframe={data?.google_swirl_iframe} productLink={data?.product_link} download_url={data?.google_swirl_download_link} views={modelAnalytics?.views} live_views={modelLiveViews} engagement_time={modelAnalytics?.engagement_time} ar_button_click={modelAnalytics?.ar_button_click} />
                </div>
                <ModelConfiguratorEditor />
            </div>
        </div>
    )
}

export default MyProductsDetails