import ThreeSixty from './React360Viewer'


const View360 = ({ data, id }) => {
  function parseUrl(url) {
    let regex = /([^/]*)(\d+)(\.\w+)$/;
    let match = url.match(regex);

    if (match) {
      return match[3];
    } else {
      throw new Error("Invalid URL format");
    }
  }

  const extension = parseUrl(data[0]);


  return (
    <div className="view-360-wrapper">
      <ThreeSixty
        amount={data.length}
        imagePath={`https://dev.api.imajin.ai/media/ar_models/${id}/360_images`}
        fileName={`{index}${extension}`}
        spinReverse="true"
      />
    </div>
  )
}

export default View360
