import React, { useEffect, useState } from 'react';
import './Checkout.scss';
import CheckoutAccardion from '../../components/CheckoutAccardion/CheckoutAccardion';
import { Link, useSearchParams } from 'react-router-dom';
import { axiosPrivate } from '../../api/api'
import CheckoutProductSkeleton from '../../components/CheckoutProductSkeleton/CheckoutProductSkeleton';
import CheckoutSummarySkeleton from '../../components/CheckoutSummarySkeleton/CheckoutSummarySkeleton';

const Checkout = () => {
    const [query, setQuery] = useSearchParams()
    const [products, setProducts] = useState(null)
    const [loading, setLoading] = useState(false)

    const getProducts = async id => {
        const result = await axiosPrivate.get(`/api/v1/requests/products/checkout/?products=${id}`)
        setProducts(result.data);
        setLoading(false)
    }

    const removeProduct = id => {
        const products = query.get('products')?.split(',')
        const updatedURL = products.filter(product => parseInt(product) !== id ? product : false)
        setQuery(`id=${updatedURL.toString()}`)
    }

    const createOrder = async () => {
        const product_id = query.get('products')?.split(',')
        const result = await axiosPrivate.post(`/api/v1/orders/?products=${product_id}`)
        window.location.replace(result.data?.data?.payment_url);
    }

    useEffect(() => {
        const product_id = query.get('products')?.split(',')
        setLoading(true)

        if (product_id[0] === '') window.location.replace('/requests')

        try {
            getProducts(product_id)
        } catch (error) {
            console.log(error);
        }
    }, [query])

    return (
        <div className='checkout-wrapper'>
            <div className='checkout-header'>
                <Link to={`/requests?id=${query.get('products')}`} className='back-home'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5 16.25L6.25 10L12.5 3.75" stroke="#555E6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span>Back Home</span>
                </Link>
            </div>

            <div className='checkout-list-head'>
                <h3 className='title'>Order summary</h3>
            </div>

            <div className='checkout-list-body'>
                <div className='checkout-list'>
                    <h3 className='title'>Price review</h3>
                    <div className='products-list'>
                        {
                            loading
                                ? [1, 2, 3].map((item, index) => <CheckoutProductSkeleton key={index} />)
                                : products?.results?.map(product => {
                                    return (
                                        <div className='product' key={product.id}>
                                            <div className='product-image'>
                                                <img src={product.images[0]} alt="product" />
                                            </div>
                                            <div className='product-details'>
                                                <div className='head'>
                                                    <p>{product.name}</p>
                                                    <button className='remove-product' onClick={() => { removeProduct(product.id) }}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18.75 5.25L5.25 18.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path d="M18.75 18.75L5.25 5.25" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div className='body'>
                                                    <div className='list'>
                                                        <p className='item'>
                                                            <span>Price</span>
                                                            <span>${product.price}</span>
                                                        </p>
                                                    </div>
                                                    {product.sku && <div className='list'>
                                                        <p className='item'>
                                                            <span>SKU</span>
                                                            <span>{product.sku}</span>
                                                        </p>
                                                    </div>}
                                                    <div className='list'>
                                                        <p className='item'>
                                                            <span>Delivery time</span>
                                                            <span>
                                                                Within {product.delivery_time} business days
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
                <div className='checkout-price'>
                    <CheckoutAccardion />
                    <div className='total-summary'>
                        <h3 className='title'>Summary</h3>
                        <ul>
                            {
                                loading
                                    ? [1, 2, 3].map((item, index) => <CheckoutSummarySkeleton key={index} />)
                                    : <>
                                        <li>
                                            <span>Order</span>
                                            <span>${products?.order_amount}</span>
                                        </li>
                                        <li>
                                            <span>Payment processing fee</span>
                                            <span>${products?.fee}</span>
                                        </li>
                                        <li>
                                            <span>Total</span>
                                            <span>${products?.total_amount}</span>
                                        </li>
                                    </>
                            }
                        </ul>
                        <div className='go-to-checkout'>
                            <button onClick={createOrder} className={`btn_primary ${loading ? 'disabled-btn' : null}`}>
                                Continue to checkout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkout