import React from 'react'
import './Success.scss'

const Success = () => {

    return (
        <div className='success-wrapper'>
            <div className='content'>
                <h4>Confirm your email address</h4>
                <p>We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.</p>
            </div>
        </div>
    )
}

export default Success