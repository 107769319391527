import React from "react"
import ContentLoader from "react-content-loader"

const CheckoutProductSkeleton = (props) => (
    <ContentLoader
        speed={2}
        width='100%'
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="4" ry="4" width="129" height="133" />
        <rect x="150" y="0" rx="4" ry="4" width="90%" height="35" />
        <rect x="150" y="55" rx="4" ry="4" width="70%" height="20" />
        <rect x="150" y="95" rx="4" ry="4" width="70%" height="20" />
    </ContentLoader>
)

export default CheckoutProductSkeleton

