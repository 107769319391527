import { createSlice } from '@reduxjs/toolkit'
import {
    getModelViewerData,
    changeBgColor,
    handleAutoRotate,
    changeAddNewStatus,
    changeShowInputStatus,
    changeAnnotations,
    changeAnnotationText,
    deleteAnnotationItem,
    deleteAnnotationLastItem,
    handleAutoLoad,
    updateVariantItem,
    changeEnvironment
} from '../actions/modelViewer'

const initialState = {
    loading: false,
    error: null,
    data: {},
    modelAnnations: {
        addNew: false,
        showInput: false,
    }
}

const modelViewerSlice = createSlice({
    name: 'modelViewer',
    initialState,
    reducers: {
        changeColor: changeBgColor,
        autoRotate: handleAutoRotate,
        autoLoad: handleAutoLoad,
        addNew: changeAddNewStatus,
        showInput: changeShowInputStatus,
        updateAnnotations: changeAnnotations,
        updateAnnotationText: changeAnnotationText,
        deleteAnnotation: deleteAnnotationItem,
        deleteAnnotationLast: deleteAnnotationLastItem,
        updateVariant: updateVariantItem,
        updateEnviroment: changeEnvironment,
    },
    extraReducers: {
        [getModelViewerData.pending]: (state) => {
            state.loading = true
        },
        [getModelViewerData.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getModelViewerData.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.data = payload
        },
    },
})

export const {
    changeColor,
    autoRotate,
    autoLoad,
    addNew,
    showInput,
    updateAnnotations,
    updateAnnotationText,
    deleteAnnotation,
    deleteAnnotationLast,
    updateVariant,
    updateEnviroment
} = modelViewerSlice.actions
export default modelViewerSlice.reducer