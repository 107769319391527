import React from 'react'
import './404.scss';
import image from '../../images/404.png'
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className='notfound-wrapper'>
            <div className='image'>
                <img src={image} alt="response" />
            </div>
            <h3>Oops! Something wrong…</h3>
            <p>Oops! The page you are looking for does not exist. It might have been moved or deleted.  </p>
            <div className='notfound-button-groups'>
                <Link to="/" >Back to Home</Link>
            </div>
        </div>
    )
}

export default NotFound