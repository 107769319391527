import React from 'react'

const Svg360 = ({ type, color }) => {
    return (
        type === "model"
            ? <svg style={{ pointerEvents: 'none' }} xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <path d="M12.5313 2.35078C12.4657 2.37422 11.1297 3.10547 9.55472 3.97734C6.42816 5.70703 6.34847 5.76328 6.16097 6.25078C6.06253 6.51797 6.06253 6.56016 6.06253 9.98203C6.06253 13.7695 6.05316 13.6195 6.34378 14.0367C6.43285 14.1727 6.60628 14.3227 6.82191 14.4539C7.44066 14.8336 12.3438 17.5383 12.5407 17.6086C12.7844 17.693 13.2157 17.693 13.4594 17.6086C13.5625 17.5711 14.9313 16.8258 16.4969 15.9586C19.4875 14.2992 19.5907 14.2289 19.8063 13.793L19.9141 13.5727L19.9282 10.0523C19.9375 6.53672 19.9375 6.52734 19.8391 6.25547C19.6516 5.75859 19.5719 5.70234 16.5391 4.02422C15.0157 3.18047 13.6797 2.44922 13.5625 2.39766C13.3328 2.29453 12.7469 2.26641 12.5313 2.35078ZM16.0797 4.57734C17.6032 5.42578 18.8407 6.13359 18.8219 6.15234C18.7703 6.19922 13.0375 9.37734 13 9.37734C12.9625 9.37734 7.22972 6.19922 7.17816 6.15234C7.15472 6.12422 10.9094 4.00547 12.4563 3.17578C13.0422 2.85703 12.8641 2.78672 16.0797 4.57734ZM9.90628 8.47734L12.6016 9.97266L12.625 12.8273C12.6438 15.0258 12.6625 15.7055 12.7094 15.7852C12.7797 15.9164 12.9719 15.968 13.136 15.9117C13.3657 15.832 13.3703 15.7523 13.3703 12.7664L13.375 9.99609L16.2953 8.37422L19.211 6.75234L19.225 10.0477C19.2344 12.4195 19.2203 13.3758 19.1828 13.4695C19.1547 13.5398 19.061 13.657 18.9813 13.7273C18.8641 13.8305 13.975 16.5773 13.3422 16.8961C12.9719 17.0836 13.0235 17.1023 9.95316 15.4008C8.39222 14.5336 7.07035 13.7789 7.00941 13.7227C6.75628 13.4883 6.76566 13.6195 6.76566 10.057L6.76566 6.75234L6.99066 6.86484C7.11253 6.93047 8.42503 7.65234 9.90628 8.47734Z" fill={color} stroke={color} strokeWidth="0.6" />
                <path d="M4.20951 10.7719C1.49076 12.2203 0.403258 14.2781 1.3267 16.2516C1.55638 16.7484 1.73451 17.0016 2.16576 17.4516C3.2392 18.5719 4.8517 19.4437 6.99388 20.0578C10.397 21.0328 14.6533 21.1125 18.1829 20.2687C18.5673 20.175 18.9329 20.0906 18.9986 20.0719C19.0689 20.0578 18.8251 20.2781 18.4033 20.6203C17.7423 21.1453 17.6861 21.2016 17.6861 21.3422C17.6861 21.5766 17.7986 21.7031 18.0048 21.7031C18.1595 21.6984 18.3283 21.5812 19.5611 20.5969C20.3204 19.9922 21.0001 19.4391 21.0751 19.3734C21.2298 19.2187 21.2486 18.9844 21.1079 18.8531C21.0001 18.7547 20.9439 18.7453 18.5673 18.5297L17.1283 18.4031L17.0064 18.5156C16.8236 18.6891 16.8704 18.9891 17.0908 19.0734C17.147 19.0969 17.6251 19.1531 18.1501 19.2C19.2517 19.2984 19.2564 19.2891 18.0845 19.5703C14.8173 20.3625 10.8658 20.3344 7.64076 19.5C3.61888 18.4641 1.27982 16.3922 1.77201 14.3062C1.91263 13.7156 2.23607 13.1859 2.80326 12.6328C3.26263 12.1781 3.75482 11.8219 4.44388 11.4422C5.0017 11.1328 5.07669 11.0672 5.07669 10.8703C5.07669 10.7203 4.8892 10.5469 4.72982 10.5469C4.67826 10.5469 4.44388 10.65 4.20951 10.7719Z" fill={color} stroke={color} strokeWidth="0.6" />
                <path d="M20.9715 10.6687C20.7653 10.9359 20.859 11.0578 21.5528 11.4422C24.1965 12.8953 24.984 14.8453 23.6481 16.5937C23.3668 16.9594 23.0856 17.2359 22.584 17.6344C22.2512 17.8969 22.209 17.9531 22.195 18.1031C22.1856 18.2391 22.2043 18.2906 22.3075 18.3703C22.3778 18.4219 22.4715 18.4688 22.5137 18.4688C22.7481 18.4688 23.7981 17.5687 24.234 16.9969C24.5387 16.5937 24.834 15.9422 24.9325 15.4688C25.0684 14.7891 24.9606 14.025 24.6325 13.3828C24.1309 12.3844 23.0434 11.4047 21.6793 10.7156C21.2715 10.5094 21.1028 10.5 20.9715 10.6687Z" fill={color} stroke={color} strokeWidth="0.6" />
            </svg>
            : <svg style={{ pointerEvents: 'none' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20.7675 3.16493C19.7269 3.44618 19.0519 4.46805 19.2159 5.51336C19.4128 6.74149 20.6269 7.54774 21.8175 7.23836C22.4175 7.07899 22.9378 6.64305 23.2144 6.05711C23.355 5.75711 23.3644 5.71024 23.3644 5.1993C23.3644 4.68836 23.355 4.64149 23.2144 4.34149C23.0362 3.96649 22.6425 3.53055 22.3191 3.35711C21.8456 3.0993 21.2691 3.02899 20.7675 3.16493ZM21.6066 4.58055C22.1831 4.86649 22.0659 5.76649 21.4425 5.88368C21.2175 5.92586 21.0112 5.86493 20.8284 5.70555C20.2331 5.18055 20.8894 4.21961 21.6066 4.58055Z" fill={color} />
                <path d="M5.91864 7.38281C5.29989 7.51875 4.87802 7.81875 4.6577 8.29218C4.48427 8.65781 4.4702 9.17812 4.62958 9.3375C4.72333 9.43594 4.77958 9.44531 5.17333 9.44531C5.57177 9.44531 5.62802 9.43594 5.73114 9.3375C5.81083 9.25781 5.86239 9.15 5.88114 8.99531C5.9327 8.625 6.01708 8.55937 6.45302 8.54062C6.91708 8.52187 7.09052 8.61094 7.1702 8.91094C7.26395 9.24844 7.14208 9.73125 6.94052 9.83906C6.89364 9.86719 6.73427 9.90469 6.59364 9.91875C6.22802 9.96094 6.11552 10.0828 6.11552 10.4531C6.11552 10.8422 6.21395 10.9406 6.68739 11.0062C6.98739 11.0484 7.06708 11.0766 7.18895 11.1984C7.32489 11.3391 7.33427 11.3672 7.34833 11.7562C7.37177 12.4312 7.17489 12.6562 6.55145 12.6562C6.06864 12.6562 5.76395 12.4922 5.76395 12.2344C5.76395 12.0141 5.6702 11.8219 5.53895 11.775C5.1827 11.6391 4.6577 11.6766 4.48427 11.85C4.40927 11.925 4.39052 12.0047 4.3952 12.2203C4.40927 13.2 5.26239 13.8703 6.4952 13.875C7.78427 13.875 8.56239 13.3031 8.72177 12.2344C8.84833 11.3812 8.61864 10.7859 8.05614 10.4953L7.87333 10.4062L8.07958 10.2047C8.38427 9.90937 8.49677 9.57187 8.50145 8.95312C8.50614 8.39531 8.41708 8.12344 8.14052 7.8375C7.72333 7.40156 6.74833 7.20468 5.91864 7.38281Z" fill={color} />
                <path d="M11.4797 7.36406C10.8891 7.44844 10.35 7.77656 10.0922 8.20781C9.82031 8.66719 9.79687 8.85 9.79687 10.5797C9.79688 11.5547 9.81563 12.2297 9.84844 12.3844C10.0547 13.3406 10.8141 13.875 11.9766 13.875C12.9094 13.875 13.5984 13.5281 13.9172 12.8953C14.2266 12.2953 14.2359 11.2641 13.9406 10.6781C13.6594 10.1203 13.125 9.84844 12.3 9.84375C11.8828 9.84375 11.5641 9.91875 11.3531 10.0734C11.2734 10.125 11.1984 10.1719 11.1797 10.1719C11.1609 10.1719 11.1516 9.93281 11.1656 9.6375C11.1844 9.23438 11.2125 9.05625 11.2828 8.92031C11.4609 8.56875 12.0188 8.42344 12.4453 8.62031C12.6891 8.72813 12.7406 8.79844 12.7922 9.075C12.8438 9.35625 12.9938 9.49688 13.2891 9.53906C13.6078 9.59063 13.8891 9.52969 14.0203 9.39375C14.1797 9.225 14.1797 8.7 14.025 8.38125C13.6266 7.57031 12.675 7.18594 11.4797 7.36406ZM12.4969 11.0625C12.6844 11.2359 12.75 11.4375 12.75 11.8453C12.75 12.2578 12.6703 12.4734 12.4547 12.6281C12.2625 12.7688 11.6906 12.7688 11.4984 12.6281C11.2547 12.4453 11.1844 12.2578 11.1844 11.8125C11.1844 11.1234 11.3906 10.9078 12.0094 10.9313C12.3141 10.9453 12.3844 10.9641 12.4969 11.0625Z" fill={color} />
                <path d="M16.8281 7.36455C16.3078 7.45361 15.8156 7.7583 15.5719 8.14736C15.2672 8.63018 15.2578 8.70517 15.2578 10.5942C15.2578 12.4974 15.2719 12.6099 15.5719 13.0599C15.9188 13.5708 16.4484 13.8239 17.2734 13.8614C18.1359 13.9036 18.6844 13.7161 19.125 13.2333C19.2516 13.0927 19.4016 12.863 19.4625 12.713C19.5656 12.4552 19.5703 12.3942 19.5844 10.7583C19.5984 9.57705 19.5844 8.99111 19.5469 8.81299C19.4719 8.46611 19.2469 8.04893 19.0172 7.84736C18.5344 7.41143 17.6859 7.22393 16.8281 7.36455ZM17.8969 8.6583C17.9672 8.70986 18.0656 8.82236 18.1172 8.91143C18.2063 9.06143 18.2109 9.15518 18.2109 10.5942C18.2109 12.0474 18.2063 12.1271 18.1125 12.2817C17.9578 12.5442 17.7516 12.6567 17.4141 12.6567C17.0719 12.6567 16.8516 12.5396 16.725 12.2817C16.65 12.1364 16.6406 11.9536 16.6406 10.6177C16.6406 9.23486 16.65 9.10361 16.7344 8.91611C16.8047 8.76611 16.875 8.69111 17.025 8.6208C17.2875 8.49893 17.7094 8.51768 17.8969 8.6583Z" fill={color} />
                <path d="M2.60153 9.65997C1.97341 9.99747 1.05935 10.785 0.674971 11.324C-0.571904 13.0678 -0.0609663 15.0225 2.03903 16.5318C3.57653 17.6428 6.2156 18.5193 8.92966 18.8287C9.2906 18.8662 9.61404 18.9084 9.65154 18.9178C9.69372 18.9318 9.55779 19.1053 9.26716 19.3959C8.79841 19.874 8.73747 19.9865 8.77966 20.2959C8.83122 20.6803 9.19216 20.9428 9.58122 20.8818C9.75466 20.8537 9.88122 20.7412 10.8656 19.7615C12.4078 18.2287 12.4078 18.3928 10.9312 16.9115C10.3734 16.349 9.8531 15.8615 9.76872 15.8193C9.18747 15.5475 8.58279 16.11 8.81247 16.7147C8.8406 16.7803 8.99997 16.9865 9.17341 17.1647L9.48747 17.4881L9.30466 17.46C8.29216 17.324 7.96404 17.2725 7.47654 17.174C4.48591 16.5693 2.2406 15.3647 1.59841 14.0193C1.44372 13.6959 1.42966 13.6256 1.42966 13.26C1.42966 12.8897 1.44372 12.8334 1.61247 12.4959C1.84216 12.0225 2.37653 11.4787 3.03278 11.0475C3.37497 10.8225 3.52497 10.6912 3.58122 10.574C3.88591 9.93653 3.22966 9.32715 2.60153 9.65997Z" fill={color} />
                <path d="M21.1441 9.94256C20.891 10.116 20.7925 10.2801 20.7925 10.5285C20.7925 10.6551 20.8207 10.8004 20.8488 10.8566C20.8816 10.9129 21.1066 11.1191 21.3503 11.3113C22.5832 12.2957 22.8925 13.2847 22.2785 14.2597C22.105 14.5363 21.5941 15.0566 21.2332 15.3238C19.9535 16.266 17.6425 17.0676 15.28 17.3863C14.7035 17.466 14.5066 17.6113 14.4175 18.0285C14.3753 18.216 14.5207 18.5676 14.6847 18.6754C14.9191 18.8254 15.0738 18.8394 15.7019 18.7457C21.1253 17.9347 24.491 15.4644 23.9332 12.6988C23.7738 11.9301 23.3988 11.2972 22.7003 10.6363C21.9035 9.87693 21.5003 9.69881 21.1441 9.94256Z" fill={color} />
            </svg>
    )
}

export default Svg360