import React, { useEffect, useState } from 'react'
import './Home.scss'
import SideMenu from '../../components/SideMenu/SideMenu'
import { axiosPrivate } from '../../api/api';
import { Link } from 'react-router-dom';
import SkuSkeleton from '../../components/HomePageSkeletons/SkuSkeleton';
import HomeGrafic from '../../components/HomeGrafic/HomeGrafic';

const Home = () => {
    const [analytics, setAnalytics] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)

            try {
                const data = await axiosPrivate.get('/api/v1/analytics')
                setAnalytics(data.data);
            } catch (error) {
                console.log(error);
            }
            setLoading(false)
        }

        getData()
    }, [])

    return (
        <>
            <SideMenu />
            <div className='home-wrapper'>
                <div className="home-main-content">
                    <div className="left">
                        <div className='all-time-data'>
                            <div className="item">
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M12 5.75C4.5 5.75 1.5 12.5 1.5 12.5C1.5 12.5 4.5 19.25 12 19.25C19.5 19.25 22.5 12.5 22.5 12.5C22.5 12.5 19.5 5.75 12 5.75Z" stroke="#E1A829" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 16.25C14.0711 16.25 15.75 14.5711 15.75 12.5C15.75 10.4289 14.0711 8.75 12 8.75C9.92893 8.75 8.25 10.4289 8.25 12.5C8.25 14.5711 9.92893 16.25 12 16.25Z" fill="#E1A829" stroke="#F4F5F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="content">
                                    <p>Product views</p>
                                    <h3>{loading ? 0 : analytics?.views}</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 7.5V12" stroke="#E66238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.9 14.25L12 12" stroke="#E66238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.2656 9.34766H21.0156V5.59766" stroke="#E66238" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.8282 17.8313C16.6747 18.9857 15.2047 19.7721 13.6042 20.091C12.0036 20.4099 10.3445 20.2469 8.8366 19.6227C7.32872 18.9984 6.03985 17.941 5.13303 16.5842C4.22621 15.2273 3.74219 13.632 3.74219 12C3.74219 10.368 4.22621 8.7727 5.13303 7.41585C6.03985 6.059 7.32872 5.00158 8.8366 4.37735C10.3445 3.75313 12.0036 3.59014 13.6042 3.90902C15.2047 4.22789 16.6747 5.0143 17.8282 6.16875L21.0157 9.34688" stroke="#E66238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="content">
                                    <p>Time on product</p>
                                    <h3>{loading ? 0 : analytics?.engagement_time}</h3>
                                </div>
                            </div>
                            <div className="item">
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M15.7524 10.5V9.375C15.7524 8.87772 15.5549 8.40081 15.2032 8.04917C14.8516 7.69754 14.3747 7.5 13.8774 7.5C13.3801 7.5 12.9032 7.69754 12.5516 8.04917C12.1999 8.40081 12.0024 8.87772 12.0024 9.375V3.375C12.0024 2.87772 11.8049 2.40081 11.4532 2.04917C11.1016 1.69754 10.6247 1.5 10.1274 1.5C9.63012 1.5 9.1532 1.69754 8.80157 2.04917C8.44994 2.40081 8.2524 2.87772 8.2524 3.375V14.7469L6.19927 11.1844C5.95063 10.753 5.54081 10.438 5.05995 10.3088C4.5791 10.1796 4.0666 10.2467 3.63521 10.4953C3.20382 10.744 2.88887 11.1538 2.75965 11.6346C2.63042 12.1155 2.69751 12.628 2.94615 13.0594C6.0024 19.5 7.85865 21.75 12.0024 21.75C12.9873 21.75 13.9626 21.556 14.8725 21.1791C15.7825 20.8022 16.6093 20.2497 17.3057 19.5533C18.0021 18.8569 18.5546 18.0301 18.9315 17.1201C19.3084 16.2102 19.5024 15.2349 19.5024 14.25V10.5C19.5024 10.0027 19.3049 9.52581 18.9532 9.17417C18.6016 8.82254 18.1247 8.625 17.6274 8.625C17.1301 8.625 16.6532 8.82254 16.3016 9.17417C15.9499 9.52581 15.7524 10.0027 15.7524 10.5V10.5Z" stroke="#7159EA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="content">
                                    <p>View in AR clicks</p>
                                    <h3>{loading ? 0 : analytics?.ar_button_click}</h3>
                                </div>
                            </div>
                        </div>
                        <HomeGrafic />
                        <div className="recent-activity">
                            <h3 className='head-title'>Request activity</h3>
                            <div className="table">
                                <ul className='table-head'>
                                    <li>Image</li>
                                    <li>Product name</li>
                                    <li>SKU</li>
                                    <li className='d-m-none'>Created at</li>
                                    <li>Status</li>
                                </ul>
                                <ul className="table-body">
                                    {
                                        loading
                                            ? [1, 2, 3, 4].map(item => <SkuSkeleton key={item} />)
                                            : analytics?.requests?.length > 0
                                                ? analytics?.requests?.map(item => {
                                                    return <Link to={`/requests?status=${item.status}`} className='first' key={item.id}>
                                                        <ul>
                                                            <li>
                                                                <div className='image'>
                                                                    <img src={item.image} alt="product" />
                                                                </div>
                                                            </li>
                                                            <li>{item.name}</li>
                                                            <li>{item.id}</li>
                                                            <li className='d-m-none'>{item.created_at.slice(0, 10)}</li>
                                                            <li className='status-home'>
                                                                <div className={`status ${item.status}`}>
                                                                    {item.status.split('_').join(' ')}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </Link>
                                                })
                                                : <div className='empty-request-wrapper'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                        <path d="M25 31.25H18.75V25L37.5 6.25L43.75 12.5L25 31.25Z" stroke="#E8E9EA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M32.8125 10.9375L39.0625 17.1875" stroke="#E8E9EA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M42.1875 23.4375V40.625C42.1875 41.0394 42.0229 41.4368 41.7299 41.7299C41.4368 42.0229 41.0394 42.1875 40.625 42.1875H9.375C8.9606 42.1875 8.56317 42.0229 8.27015 41.7299C7.97712 41.4368 7.8125 41.0394 7.8125 40.625V9.375C7.8125 8.9606 7.97712 8.56317 8.27015 8.27015C8.56317 7.97712 8.9606 7.8125 9.375 7.8125H26.5625" stroke="#E8E9EA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <p>There are no activities currently available to you.
                                                        Please add new product.</p>
                                                </div>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="my-orders">
                            <ul className="analytics">
                                <Link to="/orders">
                                    <p className='title'>All Orders</p>
                                    <p className="count">{loading ? 0 : analytics?.orders?.all}</p>
                                </Link>
                                <Link to="/orders?status=revision&page=1">
                                    <p className='title'>Review & feedback</p>
                                    <p className="count">{loading ? 0 : analytics?.orders?.under_revision}</p>
                                </Link>
                                <Link to="/orders?status=ready&page=1">
                                    <p className='title'>Ready</p>
                                    <p className="count">{loading ? 0 : analytics?.orders?.ready}</p>
                                </Link>
                            </ul>
                        </div>
                        <div className="top-performing">
                            <div className="title">
                                <h3>Top Performing Products</h3>
                            </div>
                            <div className="table-head">
                                <p>Product info</p>
                                <p>View</p>
                                <p>Time</p>
                            </div>
                            <ul>
                                {
                                    loading
                                        ? [1, 2, 3, 4].map(item => <SkuSkeleton key={item} />)
                                        : analytics?.top_skus?.length > 0
                                            ? analytics?.top_skus?.map(item => {
                                                return <Link to={`/my-products/${item.id}`} key={item.id}>
                                                    <div className="detail">
                                                        <div className="image">
                                                            <img src={item.image} alt="product" />
                                                        </div>
                                                        <div className='text'>
                                                            <h3>{item.name}</h3>
                                                            <p>{item.sku}</p>
                                                        </div>
                                                    </div>
                                                    <div className="item">
                                                        <p>{item.views}</p>
                                                    </div>
                                                    <div className="item">
                                                        <p>{item.engagement_time} min</p>
                                                    </div>
                                                </Link>
                                            })
                                            : <div className='empty-sku-wrapper'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                                                    <path d="M12.7915 35.418H38.1873C42.1457 35.418 44.229 33.3346 44.229 29.3763V4.16797H6.729V29.3763C6.74984 33.3346 8.83318 35.418 12.7915 35.418Z" stroke="#E8E9EA" strokeWidth="1.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M4.66797 4.16797H46.3346" stroke="#E8E9EA" strokeWidth="1.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M17.168 45.8346L25.5013 41.668V35.418" stroke="#E8E9EA" strokeWidth="1.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M33.8333 45.8346L25.5 41.668" stroke="#E8E9EA" strokeWidth="1.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M16.125 22.918L22.6875 17.4388C23.2083 17.0013 23.8958 17.1263 24.25 17.7096L26.75 21.8763C27.1042 22.4596 27.7917 22.5638 28.3125 22.1471L34.875 16.668" stroke="#E8E9EA" strokeWidth="1.8125" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <p>Performances of the product are
                                                    not available</p>
                                            </div>
                                }
                            </ul>
                        </div>
                        <div className="model-example">
                            <p>Try 3D Model</p>
                            <button className='view-in-your-space-btn'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_2360_12372)">
                                        <path d="M7.33073 13.0307L3.9974 11.1141C3.78628 10.9918 3.62251 10.8281 3.50606 10.6227C3.38917 10.417 3.33073 10.1918 3.33073 9.9474V6.13073C3.33073 5.88628 3.38917 5.66117 3.50606 5.4554C3.62251 5.25006 3.78628 5.08628 3.9974 4.96406L7.33073 3.0474C7.54184 2.92517 7.76406 2.86406 7.9974 2.86406C8.23073 2.86406 8.45295 2.92517 8.66406 3.0474L11.9974 4.96406C12.2085 5.08628 12.3725 5.25006 12.4894 5.4554C12.6058 5.66117 12.6641 5.88628 12.6641 6.13073V9.9474C12.6641 10.1918 12.6058 10.417 12.4894 10.6227C12.3725 10.8281 12.2085 10.9918 11.9974 11.1141L8.66406 13.0307C8.45295 13.153 8.23073 13.2141 7.9974 13.2141C7.76406 13.2141 7.54184 13.153 7.33073 13.0307ZM7.33073 11.4974V8.43073L4.66406 6.8974V9.96406L7.33073 11.4974ZM8.66406 11.4974L11.3307 9.96406V6.8974L8.66406 8.43073V11.4974ZM0.664062 3.9974V2.66406C0.664062 2.10851 0.858507 1.63628 1.2474 1.2474C1.63628 0.858507 2.10851 0.664062 2.66406 0.664062H3.9974V1.9974H2.66406C2.47517 1.9974 2.31695 2.06117 2.1894 2.18873C2.0614 2.31673 1.9974 2.47517 1.9974 2.66406V3.9974H0.664062ZM2.66406 15.3307C2.10851 15.3307 1.63628 15.1363 1.2474 14.7474C0.858507 14.3585 0.664062 13.8863 0.664062 13.3307V11.9974H1.9974V13.3307C1.9974 13.5196 2.0614 13.6778 2.1894 13.8054C2.31695 13.9334 2.47517 13.9974 2.66406 13.9974H3.9974V15.3307H2.66406ZM11.9974 15.3307V13.9974H13.3307C13.5196 13.9974 13.6778 13.9334 13.8054 13.8054C13.9334 13.6778 13.9974 13.5196 13.9974 13.3307V11.9974H15.3307V13.3307C15.3307 13.8863 15.1363 14.3585 14.7474 14.7474C14.3585 15.1363 13.8863 15.3307 13.3307 15.3307H11.9974ZM13.9974 3.9974V2.66406C13.9974 2.47517 13.9334 2.31673 13.8054 2.18873C13.6778 2.06117 13.5196 1.9974 13.3307 1.9974H11.9974V0.664062H13.3307C13.8863 0.664062 14.3585 0.858507 14.7474 1.2474C15.1363 1.63628 15.3307 2.10851 15.3307 2.66406V3.9974H13.9974ZM7.9974 7.26406L10.6641 5.73073L7.9974 4.1974L5.33073 5.73073L7.9974 7.26406Z" fill="#4F8BF0" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2360_12372">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                <span>View in your space</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home