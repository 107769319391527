import React, { useState } from 'react'
import './Header.scss'
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material'
import { Link } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { logout } from '../../helper/helper';

const Header = () => {
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <div className='container'>
            <div className='header-wrapper'>
                <button className='d-d-none menu-btn'><MenuRoundedIcon /></button>
                <div className='logo-wrapper'>
                    <Link to='/'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="110" height="23" viewBox="0 0 110 23" fill="none">
                            <path d="M37.7626 17.9883V5.28406H40.7553V17.9883H37.7626ZM39.2674 3.48098C38.7933 3.48098 38.3854 3.32382 38.0437 3.00953C37.7021 2.68971 37.5312 2.30649 37.5312 1.85986C37.5312 1.40771 37.7021 1.02449 38.0437 0.710186C38.3854 0.390375 38.7933 0.230469 39.2674 0.230469C39.7467 0.230469 40.1547 0.390375 40.4908 0.710186C40.8324 1.02449 41.0035 1.40771 41.0035 1.85986C41.0035 2.30649 40.8324 2.68971 40.4908 3.00953C40.1547 3.32382 39.7467 3.48098 39.2674 3.48098Z" fill="#313638" />
                            <path d="M42.8984 17.9869V5.28261H45.7587V7.44132H45.9074C46.1723 6.71347 46.6102 6.14555 47.2222 5.73752C47.8339 5.32395 48.564 5.11719 49.4129 5.11719C50.2725 5.11719 50.9974 5.32672 51.5873 5.74579C52.1824 6.15932 52.6011 6.72451 52.8438 7.44132H52.976C53.2571 6.73554 53.7311 6.17312 54.3981 5.75405C55.0703 5.32949 55.8668 5.11719 56.7872 5.11719C57.9556 5.11719 58.9093 5.48661 59.6475 6.2255C60.3861 6.96438 60.7554 8.04235 60.7554 9.45947V17.9869H57.7546V9.92264C57.7546 9.13412 57.545 8.55793 57.1263 8.19401C56.7072 7.82455 56.1948 7.63985 55.5886 7.63985C54.8663 7.63985 54.3015 7.86592 53.8938 8.31806C53.4913 8.7647 53.2902 9.34642 53.2902 10.0632V17.9869H50.3554V9.79856C50.3554 9.14239 50.157 8.61857 49.7601 8.22708C49.3687 7.83558 48.8562 7.63985 48.2224 7.63985C47.7926 7.63985 47.4012 7.75011 47.0485 7.97067C46.6958 8.18574 46.4147 8.49175 46.2051 8.88875C45.9959 9.28025 45.8911 9.73792 45.8911 10.2617V17.9869H42.8984Z" fill="#313638" />
                            <path d="M66.5149 18.2433C65.7103 18.2433 64.9857 18.0999 64.3408 17.8132C63.7014 17.5209 63.1942 17.0908 62.8194 16.5229C62.4501 15.955 62.2656 15.2547 62.2656 14.4221C62.2656 13.7052 62.398 13.1125 62.6626 12.6438C62.9271 12.1751 63.2879 11.8001 63.7454 11.5189C64.2028 11.2377 64.7182 11.0255 65.2915 10.8821C65.87 10.7332 66.468 10.6257 67.0853 10.5595C67.8294 10.4823 68.4329 10.4134 68.8959 10.3527C69.3589 10.2866 69.695 10.1873 69.9045 10.055C70.1196 9.91711 70.2269 9.70484 70.2269 9.4181V9.36849C70.2269 8.74539 70.0422 8.26292 69.6732 7.92106C69.3039 7.57918 68.7719 7.40825 68.0776 7.40825C67.3445 7.40825 66.7631 7.56817 66.333 7.88796C65.9087 8.20778 65.6221 8.5855 65.4734 9.0211L62.6789 8.62408C62.8994 7.85212 63.2632 7.20699 63.7701 6.68867C64.2773 6.16485 64.8975 5.77336 65.6302 5.51419C66.3633 5.24951 67.1737 5.11719 68.061 5.11719C68.6727 5.11719 69.2818 5.18887 69.8879 5.33222C70.4944 5.47558 71.0482 5.71268 71.5496 6.04353C72.0513 6.36885 72.4534 6.81275 72.7567 7.37518C73.0654 7.9376 73.2196 8.64064 73.2196 9.48428V17.9869H70.3428V16.2417H70.2435C70.0617 16.5946 69.8053 16.9254 69.4747 17.2342C69.1494 17.5375 68.7387 17.7829 68.2429 17.9703C67.7522 18.1523 67.1763 18.2433 66.5149 18.2433ZM67.2922 16.0432C67.8928 16.0432 68.4137 15.9246 68.8546 15.6875C69.2954 15.4449 69.6345 15.1251 69.8713 14.7281C70.114 14.3311 70.2351 13.8982 70.2351 13.4295V11.9325C70.1414 12.0097 69.9816 12.0814 69.7558 12.1475C69.5352 12.2137 69.2873 12.2716 69.0117 12.3212C68.7361 12.3709 68.4632 12.415 68.1931 12.4536C67.9231 12.4922 67.6888 12.5253 67.4906 12.5528C67.0439 12.6135 66.6444 12.7127 66.2917 12.8506C65.939 12.9884 65.6608 13.1814 65.4568 13.4295C65.2528 13.6722 65.1509 13.9865 65.1509 14.3724C65.1509 14.9238 65.352 15.3401 65.7545 15.6213C66.1567 15.9026 66.6694 16.0432 67.2922 16.0432Z" fill="#313638" />
                            <path d="M75.2995 5.28406H78.2922V18.7161C78.2922 19.6315 78.1184 20.3869 77.7713 20.9824C77.4241 21.5779 76.9227 22.0218 76.2668 22.314C75.6108 22.6063 74.8144 22.7524 73.8776 22.7524C73.7673 22.7524 73.6652 22.7496 73.5715 22.7441C73.4778 22.7441 73.3759 22.7414 73.2656 22.7359V20.2959C73.3483 20.3014 73.4228 20.3042 73.4888 20.3042C73.5552 20.3097 73.6239 20.3125 73.6957 20.3125C74.2687 20.3125 74.6793 20.1718 74.9276 19.8906C75.1755 19.615 75.2995 19.2097 75.2995 18.6748V5.28406ZM76.7877 3.48098C76.3081 3.48098 75.8975 3.32382 75.5558 3.00953C75.2194 2.68971 75.0515 2.30649 75.0515 1.85986C75.0515 1.40771 75.2194 1.02449 75.5558 0.710186C75.8975 0.390375 76.3081 0.230469 76.7877 0.230469C77.2614 0.230469 77.6665 0.390375 78.0029 0.710186C78.3445 1.02449 78.5154 1.40771 78.5154 1.85986C78.5154 2.30649 78.3445 2.68971 78.0029 3.00953C77.6665 3.32382 77.2614 3.48098 76.7877 3.48098Z" fill="#313638" />
                            <path d="M80.4423 17.9883V5.28406H83.435V17.9883H80.4423ZM81.9471 3.48098C81.473 3.48098 81.065 3.32382 80.7234 3.00953C80.3818 2.68971 80.2109 2.30649 80.2109 1.85986C80.2109 1.40771 80.3818 1.02449 80.7234 0.710186C81.065 0.390375 81.473 0.230469 81.9471 0.230469C82.4267 0.230469 82.8343 0.390375 83.1705 0.710186C83.5124 1.02449 83.6832 1.40771 83.6832 1.85986C83.6832 2.30649 83.5124 2.68971 83.1705 3.00953C82.8343 3.32382 82.4267 3.48098 81.9471 3.48098Z" fill="#313638" />
                            <path d="M88.5708 10.543V17.9869H85.5781V5.28261H88.4384V7.44132H88.5874C88.8793 6.73004 89.3452 6.16485 89.9845 5.74579C90.6294 5.32672 91.4256 5.11719 92.3737 5.11719C93.2499 5.11719 94.0132 5.30465 94.6636 5.67961C95.3195 6.05457 95.8268 6.59769 96.185 7.309C96.5487 8.02031 96.7277 8.88325 96.7222 9.89781V17.9869H93.7295V10.361C93.7295 9.51185 93.5089 8.84741 93.068 8.3677C92.6327 7.88796 92.0291 7.64812 91.2577 7.64812C90.7342 7.64812 90.2683 7.76391 89.8606 7.99551C89.4581 8.22158 89.1412 8.54966 88.9098 8.97973C88.6837 9.40983 88.5708 9.93091 88.5708 10.543Z" fill="#313638" />
                            <path d="M29.0649 18.0282V6.27314C29.0649 3.05581 27.2548 -0.554257 23.2421 0.44764C19.2295 1.44954 16.7275 6.92896 15.8101 8.13803C15.4671 8.34584 15.5878 10.5435 15.2266 10.7902L16.3327 17.4764C20.3852 14.0804 23.2421 5.51559 23.2421 9.32657V18.0282H29.0649Z" fill="url(#paint0_linear_1917_14080)" />
                            <path d="M11.7434 15.117V9.32814C11.6112 6.88057 8.99236 6.05047 6.07282 8.22409L0.25 12.6301V6.41841C2.56566 4.78431 4.41217 3.28572 6.07282 2.27936C8.20121 0.989529 9.95329 0.449219 11.7434 0.449219C14.9592 0.449219 17.5662 3.05738 17.5662 6.2747V12.1508C17.5662 13.8974 17.2372 16.637 16.706 17.1712C16.1797 17.7004 15.4549 18.0298 14.6547 18.0298C13.0468 18.0298 11.7434 16.7257 11.7434 15.117Z" fill="url(#paint1_linear_1917_14080)" />
                            <path d="M17.5711 12.1484C17.5711 13.895 17.2422 16.6346 16.7109 17.1688C17.4693 16.5016 18.2739 15.4288 19.3639 13.8927C18.5108 14.5095 17.5686 14.5595 17.5711 12.1484Z" fill="url(#paint2_linear_1917_14080)" />
                            <path d="M6.07282 0.269531H0.25V6.41599C2.56566 4.78189 4.41217 3.28331 6.07282 2.27693V0.269531Z" fill="url(#paint3_linear_1917_14080)" />
                            <path d="M0.25 18.0284H6.07282V8.22266L0.25 12.6286V18.0284Z" fill="url(#paint4_linear_1917_14080)" />
                            <defs>
                                <linearGradient id="paint0_linear_1917_14080" x1="15.2035" y1="9.6045" x2="26.0746" y2="18.0446" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#2B6CEE" />
                                    <stop offset="0.5" stopColor="#2790F9" />
                                    <stop offset="1" stopColor="#2BBBF7" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_1917_14080" x1="-0.768805" y1="9.82165" x2="15.7251" y2="13.46" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#2BBBF7" />
                                    <stop offset="0.494792" stopColor="#2790F9" />
                                    <stop offset="1" stopColor="#2BBBF7" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_1917_14080" x1="-0.763819" y1="9.8193" x2="15.7301" y2="13.4577" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#2BBBF7" />
                                    <stop offset="0.494792" stopColor="#2790F9" />
                                    <stop offset="1" stopColor="#2BBBF7" />
                                </linearGradient>
                                <linearGradient id="paint3_linear_1917_14080" x1="3.1614" y1="0.269531" x2="3.1614" y2="18.0274" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#2B6CEE" />
                                    <stop offset="0.515625" stopColor="#2790F9" />
                                    <stop offset="1" stopColor="#2BBBF7" />
                                </linearGradient>
                                <linearGradient id="paint4_linear_1917_14080" x1="3.1614" y1="0.270519" x2="3.1614" y2="18.0284" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#2B6CEE" />
                                    <stop offset="0.515625" stopColor="#2790F9" />
                                    <stop offset="1" stopColor="#2BBBF7" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </Link>
                </div>
                <div className='right-side d-m-none'>
                    <Link to='/new-request' className='add-new-header'>
                        <AddRoundedIcon />
                        <span>Add new</span>
                    </Link>
                    <div className='help-button-wrapper'>
                        <Link to="/">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M12 21.5C16.9706 21.5 21 17.4706 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5C3 17.4706 7.02944 21.5 12 21.5Z" stroke="#8D8D8D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 18.5C12.6213 18.5 13.125 17.9963 13.125 17.375C13.125 16.7537 12.6213 16.25 12 16.25C11.3787 16.25 10.875 16.7537 10.875 17.375C10.875 17.9963 11.3787 18.5 12 18.5Z" fill="#8D8D8D" />
                                <path d="M12 14V13.25C12.5192 13.25 13.0267 13.096 13.4584 12.8076C13.8901 12.5192 14.2265 12.1092 14.4252 11.6295C14.6239 11.1499 14.6758 10.6221 14.5746 10.1129C14.4733 9.60369 14.2233 9.13596 13.8562 8.76885C13.489 8.40173 13.0213 8.15173 12.5121 8.05044C12.0029 7.94915 11.4751 8.00114 10.9955 8.19982C10.5158 8.3985 10.1058 8.73495 9.81739 9.16663C9.52895 9.59831 9.375 10.1058 9.375 10.625" stroke="#8D8D8D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Link>
                    </div>
                    <div className='user-details'>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="avatar" src="https://res.cloudinary.com/minimal-ui/image/upload/v1614655910/upload_minimal/avatar/minimal_avatar.jpg" />
                        </IconButton>
                        <Menu
                            sx={{ mt: "50px" }}
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem className='menu-item'>
                                <div className='user-profile-dropdown user-profile-dropdown-border'>
                                    <div className="image" style={{ background: "#F6FAFF" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                            <path d="M10.5 13.125C13.3995 13.125 15.75 10.7745 15.75 7.875C15.75 4.9755 13.3995 2.625 10.5 2.625C7.6005 2.625 5.25 4.9755 5.25 7.875C5.25 10.7745 7.6005 13.125 10.5 13.125Z" stroke="#7E899B" strokeWidth="2" strokeMiterlimit="10" />
                                            <path d="M2.54297 17.7195C3.34928 16.3226 4.50912 15.1626 5.90588 14.3561C7.30265 13.5496 8.88711 13.125 10.5 13.125C12.1129 13.125 13.6974 13.5496 15.0941 14.3561C16.4909 15.1626 17.6507 16.3226 18.457 17.7195" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="details">
                                        <p className='name'>Rana Mammadli</p>
                                        <p className='email'>mramilemammadova@gmail.com</p>
                                    </div>
                                </div>
                            </MenuItem>
                            <MenuItem onClick={logout}>
                                <div className='user-profile-dropdown' >
                                    <div className="image">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                            <path d="M11.3887 4.4375V10.6719" stroke="#DD9B92" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M15.3262 4.94531C16.6304 5.79412 17.6255 7.04168 18.1631 8.50205C18.7006 9.96241 18.7519 11.5574 18.3092 13.0492C17.8665 14.5411 16.9535 15.85 15.7064 16.7807C14.4593 17.7115 12.9448 18.2144 11.3887 18.2144C9.83252 18.2144 8.31802 17.7115 7.07092 16.7807C5.82382 15.85 4.91089 14.5411 4.46819 13.0492C4.02549 11.5574 4.07674 9.96241 4.61428 8.50205C5.15183 7.04168 6.1469 5.79412 7.45117 4.94531" stroke="#DD9B92" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="details">
                                        <p className='name'>Logout</p>
                                    </div>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header