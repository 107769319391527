import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosPrivate } from '../../api/api';

export const getRequestNotification = createAsyncThunk('request/fetchRequestNotification', async (_, { rejectWithValue }) => {
    try {
        const result = await axiosPrivate.get('/api/v1/requests/products/notifications/')
        return result.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
