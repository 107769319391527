import React, { useEffect, useState } from 'react'
import './RequestList.scss'
import SideMenu from '../../components/SideMenu/SideMenu'
import OrderProductSkeleton from '../../components/OrderProductSkeleton/OrderProductSkeleton'
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import { Stack } from '@mui/system';
import { Box, Modal, Pagination } from '@mui/material';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { axiosPrivate } from '../../api/api'
import empty_draft from '../../images/draft.png'
import empty_need_data from '../../images/need_data.png'
import empty_under_assessment from '../../images/under_assessment.png'
import empty_ready from '../../images/ready.png'
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { changeNotifications } from '../../redux/reducers/requestNotificationReducer';
import SearchResultText from '../../components/SearchResultText/SearchResultText';

const status = [
    { name: 'Ready', slug: 'ready' },
    { name: 'Under Assesment', slug: 'under_assessment' },
    { name: 'Need Data', slug: 'need_data' },
    { name: 'Draft', slug: 'draft' },
]

const emptyMessage = {
    'ready': {
        image: empty_ready,
        title: "It may be empty now, but that won't be for long.",
        text: "To get started, simply click the Add product button to create your first request."
    },
    'under_assessment': {
        image: empty_under_assessment,
        title: "It looks like there aren't any product here yet.",
        text: "But don't worry, as soon as you send your request, it will appear on this page."
    },
    'need_data': {
        image: empty_need_data,
        title: "There are no product on this page.",
        text: "We require more information so that we can provide top-of-the-line products to you."
    },
    'draft': {
        image: empty_draft,
        title: "No drafts have been saved by you.",
        text: "Using drafts, you can save and store requests that you would like to send at a later time."
    },
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

const RequestList = () => {
    const { notification } = useSelector((state) => state.requestNotification)
    const dispatch = useDispatch()
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(status[0]);
    const [searchParams, setSearchParams] = useSearchParams()
    const [pageCount, setPageCount] = useState(null)
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [queryParams, setQueryParams] = useState({
        q: '',
        status: searchParams.get("status") || 'ready',
        order: '-delivery_time',
        page: '1',
        page_size: '5'
    })
    const location = useLocation();
    const message = location.state?.message;
    const notify = (message) => toast(message);
    const navigate = useNavigate()

    const handleUrlParams = (name, value) => {
        searchParams.set(name, value);
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (message) {
            notify(message)
            navigate(location.pathname, { replace: true })
        }
    }, [location.pathname, message, navigate])

    // * Get query params initial value from URL
    useEffect(() => {
        const keys = ["q", "status", "order", "page", "page_size"];
        const newState = { ...queryParams }

        keys.forEach(key => {
            const value = searchParams.get(key);
            if (value || value === '') {
                newState[key] = value
            }
        });

        setQueryParams(newState)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    //  * Fetch products data
    const getProducts = async () => {
        setLoading(true);
        try {
            const result = await axiosPrivate.get(`/api/v1/requests/products/?q=${queryParams.q}&status=${searchParams.get('status') || "ready"}&order=${queryParams.order}&page=${queryParams.page}&page_size=${queryParams.page_size}`);

            setProducts(result.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getProducts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams.order, queryParams.page, queryParams.page_size, queryParams.q, queryParams.status]);

    // * Get selected status default value from url
    useEffect(() => {
        setSelectedStatus(status.filter(item => queryParams.status === item.slug)[0])
    }, [queryParams.status])

    // * Pagination
    useEffect(() => {
        setPageCount(Math.ceil(parseInt(products?.count) / parseInt(queryParams.page_size)))
    }, [products?.count, queryParams.page_size])

    // * Change query params function
    const changeQueryParams = (name, data) => {
        console.log(data);
        searchParams.set(name, data)
        setSearchParams(searchParams)
        resetCheckbox()

        if (name !== "page") {
            changeQueryParams("page", 1)
        }
    }

    // * Handle filter change status
    const setNotification = async (data) => {
        try {
            await axiosPrivate.post("/api/v1/requests/products/notifications/", data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleFilter = (status) => {
        setSelectedStatus(status)
        handleUrlParams('status', status.slug)

        if (notification[status.slug]) {
            setNotification({
                [status.slug]: false
            })
            dispatch(changeNotifications(status.slug))
        }
    }

    // * Handle checkbox
    const handleCheckboxChange = (product) => {
        if (selectedProducts.includes(product)) {
            setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
        } else {
            setSelectedProducts([...selectedProducts, product]);
        }
    };

    const handleParentCheckboxChange = () => {
        setIsAllSelected(!isAllSelected);
        if (isAllSelected) {
            setSelectedProducts([]);
        } else {
            setSelectedProducts(products?.results);
        }
    };

    useEffect(() => {
        selectedProducts?.length === products?.results?.length && products?.results?.length !== 0 ? setIsAllSelected(true) : setIsAllSelected(false)
    }, [products?.results?.length, selectedProducts?.length])

    const totalPrice = selectedProducts?.reduce((sum, product) => {
        return sum + product.price;
    }, 0);

    const resetCheckbox = () => {
        setSelectedProducts([])
        setIsAllSelected(false)
    }

    // * Handle delivery time
    const handleDeliveryTime = (order) => {
        changeQueryParams('order', order === '-delivery_time' ? 'delivery_time' : '-delivery_time')
    }

    // * Handle selected elements url
    const selectedURL = selectedProducts?.map(item => item.id)

    // * Delete products
    const [modalOpen, setModelOpen] = useState(false);
    const [deletedID, setDeletedID] = useState([])
    const handleCloseModal = () => setModelOpen(false);

    const handleOpenModal = (products) => {
        setModelOpen(true)
        setDeletedID(products);
    }

    const handleDeleteProducts = (data) => {
        try {
            axiosPrivate.delete('/api/v1/requests/products/', { data: data })
            notify('Products have been successfully deleted.')
            getProducts()
            setSelectedProducts([])
        } catch (error) {
            notify(error.messages)
        }
        handleCloseModal()
    }

    // * Checkbox go back with selected products
    useEffect(() => {
        const arr = products?.results?.filter(prod => searchParams.get('id')?.split(',').includes(prod.id.toString()))
        setSelectedProducts(arr)
    }, [products?.results, searchParams])

    // * Mobile
    const handleProduct = (data) => {
        handleCheckboxChange(data)
    }

    return (
        <>
            <ToastContainer autoClose={2000} />
            <SideMenu />
            <section className='request-list-wrapper'>
                <div className="head">
                    <h3>Request List</h3>
                    <p className='d-d-none mobile-desc'>To create an order, product references are required</p>
                    <div className="title">
                        <p className='d-m-none'>To create an order, product references are required</p>
                        <select className='d-d-none mobile-custom-select' onChange={(e) => { handleFilter({ slug: e.target.value }) }}>
                            {status.map(btn => (
                                <option key={btn.slug} value={btn.slug} selected={selectedStatus.slug === btn.slug ? "selected" : null}>
                                    {btn.name}
                                </option>
                            ))}
                        </select>
                        <div className="search">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.51562 16.4062C13.3212 16.4062 16.4062 13.3212 16.4062 9.51562C16.4062 5.71004 13.3212 2.625 9.51562 2.625C5.71004 2.625 2.625 5.71004 2.625 9.51562C2.625 13.3212 5.71004 16.4062 9.51562 16.4062Z" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M14.3867 14.3887L18.3734 18.3754" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <input
                                type="text"
                                placeholder={`Search for ${selectedStatus.name}`}
                                onChange={(e) => { changeQueryParams('q', e.target.value) }}
                                value={queryParams.q}
                            />
                        </div>
                    </div>
                </div>
                <div className="filter-wrapper">
                    <div className="filter">
                        <ul>
                            {status.map(btn => (
                                <li key={btn.slug} className={selectedStatus.slug === btn.slug ? 'active' : ''}>
                                    <button
                                        onClick={() => { handleFilter(btn) }}
                                    >

                                        {btn.name} <span className='count'>
                                            {products[btn.slug]}
                                            {notification[btn.slug] && <div className="notification"></div>}
                                        </span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {queryParams.q && <SearchResultText count={products.count} text={queryParams.q} />}
                    </div>
                </div>
                <div className="table-wrapper">
                    <div className="table-header-wrapper">
                        {
                            isAllSelected || selectedProducts?.length > 0
                                ? <div className="checked-table-header">
                                    <div className="information">
                                        <div className="checkbox-wrap d-m-none">
                                            <input
                                                type="checkbox"
                                                checked={isAllSelected}
                                                onChange={handleParentCheckboxChange}
                                            />
                                        </div>
                                        <div className='products-count-wrapper'>
                                            <p className="products-count">{selectedProducts?.length} Products Selected</p>
                                            {selectedStatus.slug === 'ready' && <p className="total-price">Total price: ${totalPrice?.toFixed(2)}</p>}
                                        </div>
                                    </div>
                                    <div className="actions">
                                        {
                                            ['draft', 'need_data', 'ready'].includes(selectedStatus.slug) && <button onClick={() => { handleOpenModal(selectedURL) }} className='delete'>
                                                <DeleteOutlineRoundedIcon />
                                                <span className='d-m-none'>Delete</span>
                                            </button>
                                        }
                                        {
                                            ['draft', 'need_data'].includes(selectedStatus.slug) && <Link to={`/new-request?products=${selectedURL}`} className="edit">
                                                <BorderColorRoundedIcon />
                                                <span className='d-m-none'>Edit</span>
                                            </Link>
                                        }
                                        {
                                            ['ready'].includes(selectedStatus.slug) && <Link to={`/checkout?products=${selectedURL}`} className="checkout">
                                                <span className="d-m-none">Go to Checkout</span>
                                                <span className="d-d-none">Checkout</span>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                : <div className="table-header">
                                    <ul>
                                        <li className='d-m-none'>
                                            <input
                                                type="checkbox"
                                                checked={isAllSelected}
                                                onChange={handleParentCheckboxChange}
                                            />
                                        </li>
                                        <li>
                                            <p>Image</p>
                                        </li>
                                        <li>
                                            <p>Prod. name</p>
                                        </li>
                                        <li>
                                            <p>Price</p>
                                        </li>
                                        <li className='d-m-none'>
                                            <p>Created at</p>
                                        </li>
                                        <li className='d-m-none'>
                                            <p>Delivery time</p>
                                            {
                                                selectedStatus.slug === 'ready' && <button
                                                    className='sort-by-time'
                                                    onClick={() => { handleDeliveryTime(queryParams.order) }}
                                                >
                                                    <UnfoldMoreRoundedIcon />
                                                </button>
                                            }
                                        </li>
                                        <li>
                                            <p>Action</p>
                                        </li>
                                    </ul>
                                </div>
                        }
                    </div>
                    <div className="table-body-wrapper">
                        <div className="table-body">
                            {loading
                                ? [1, 2, 3, 4, 5].map(item => <div className='order-product-skeleton' key={item}><OrderProductSkeleton /> </div>)
                                : products?.results?.length > 0
                                    ? products?.results?.map(product => (
                                        <div key={product.id} className={`product ${selectedProducts?.includes(product) ? "selected" : null}`} onClick={() => { handleProduct(product) }}>
                                            <div className="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedProducts?.includes(product)}
                                                    onChange={() => handleCheckboxChange(product)}
                                                />
                                            </div>
                                            <div className="image">
                                                <img src={product.image} alt={product.name} />
                                            </div>
                                            <p className='name'>{product.name}</p>
                                            <p className='price'>
                                                {
                                                    product.price === null
                                                        ? '-'
                                                        : product.price === 'Awaiting'
                                                            ? <span className='awaiting'>Awaiting</span>
                                                            : '$' + product.price
                                                }
                                            </p>
                                            <p className="created-at d-m-none">{product.created_at.slice(0, 10)}</p>
                                            <p className="delivery-time d-m-none">
                                                {
                                                    product.delivery_time === null
                                                        ? '-'
                                                        : product.delivery_time === 'Awaiting'
                                                            ? <span className='awaiting'>Awaiting</span>
                                                            : product.delivery_time + ' days'
                                                }
                                            </p>
                                            <div className="action-btns">
                                                <button
                                                    onClick={() => { handleOpenModal([product.id]) }}
                                                    className={`btn delete ${!['draft', 'need_data', 'ready'].includes(selectedStatus.slug) && 'disabled'}`}
                                                >
                                                    <DeleteOutlineRoundedIcon />
                                                </button>
                                                {['ready'].includes(selectedStatus.slug) && <Link to={`/checkout?products=${product.id}`} className='btn edit'>
                                                    <AddShoppingCartRoundedIcon />
                                                </Link>}
                                                {!['ready'].includes(selectedStatus.slug) && <Link to={`/new-request?products=${product.id}`} className={`btn edit ${!['draft', 'need_data'].includes(selectedStatus.slug) && 'disabled'}`}>
                                                    <BorderColorRoundedIcon />
                                                </Link>}
                                            </div>
                                        </div>
                                    ))
                                    : <>
                                        <div className='empty-message-wrapper'>
                                            <div className="image">
                                                <img src={emptyMessage[selectedStatus.slug].image} alt="" />
                                            </div>
                                            <h3>{emptyMessage[selectedStatus.slug].title}</h3>
                                            <p>{emptyMessage[selectedStatus.slug].text}</p>
                                            {selectedStatus.slug === 'ready' && <Link to="/new-request" className='add-product-primary'>
                                                <AddRoundedIcon />
                                                Add Product
                                            </Link>}
                                        </div>
                                    </>

                            }
                        </div>
                    </div>
                    <div className='table-pagination-wrapper'>
                        <div className='rows-per-page d-m-none'>
                            <p>Rows per page</p>
                            <select value={queryParams?.page_size} onChange={(e) => { changeQueryParams('page_size', e.target.value) }}>
                                {
                                    [5, 10, 25, 50].map(option =>
                                        <option
                                            key={option}
                                            value={option || 5}
                                        >
                                            {option}
                                        </option>)
                                }
                            </select>
                        </div>
                        <div className='pagination'>
                            <Stack spacing={2}>
                                <Pagination
                                    count={pageCount || 1}
                                    page={parseInt(queryParams.page)}
                                    onChange={(e, value) => { changeQueryParams('page', value) }}
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
            </section >
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="delete-modal-wrapper">
                        <h3>Are you sure ?</h3>
                        <p>Are you sure you want to delete this products? </p>
                        <div className="modal-btn-groups">
                            <button
                                className='cancel'
                                onClick={handleCloseModal}
                            >Cancel</button>
                            <button
                                className='delete'
                                onClick={() => { handleDeleteProducts(deletedID) }}
                            >Delete</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default RequestList