import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { socialAuth } from '../../redux/reducers/userReducer';

const style = {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}

const Verify = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [searchParams,] = useSearchParams()

    const state = searchParams.get('state')
    const code = searchParams.get('code')

    useEffect(() => {

        if (!state || !code) return;

        const socialAuthenticate = async () => {
            setLoading(true);
            const authProvider = localStorage.getItem("authProvider");

            try {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL}social-auth/o/${authProvider}2/?state=${state}&code=${code}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        credentials: 'include',
                    }
                );

                if (!res.ok) {
                    window.location.href = '/login';
                    setLoading(false);
                    return;
                }

                const data = await res.json();
                dispatch(socialAuth(data));
                setLoading(false);
                window.location.href = '/';
            } catch (err) {
                console.log('catch', err);
                window.location.href = '/login';
                setLoading(false);
                return;
            }
        };

        socialAuthenticate();

    }, [code, state, dispatch])

    return (
        <div style={style}>
            {!loading && (
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            )}
        </div>
    )
}

export default Verify