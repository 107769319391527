import React, { useState, useRef } from 'react'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { useDispatch } from 'react-redux';
import { deleteAnnotation, updateAnnotationText } from '../../redux/reducers/modelViewerReducer';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';

const AnnotationListItem = ({ id, text }) => {
    const dispatch = useDispatch()
    const inputRef = useRef()
    const [read, setRead] = useState(true)
    const [inputValue, setInputValue] = useState(text)
    const [annotainError, setAnnotationError] = useState('')

    const handleEdit = () => {
        inputRef.current.focus()
        setRead(false)
    }

    const handleSubmitForm = event => {
        event.preventDefault()
        if (!read && inputValue.length > 0) {

            dispatch(updateAnnotationText({
                id,
                text: inputValue
            }))

            setRead(true)
            setAnnotationError('')
        }
    }

    const handleAnnotationText = e => {
        const text = e.target.value;
        if (text.length === 100) {
            setAnnotationError('Annotation text must be less than 100 characters.')
        } else {
            setInputValue(e.target.value)
            setAnnotationError('')
        }
    }

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setInputValue(text)
            setRead(true)
            setAnnotationError('')
        }
    }

    document.addEventListener("click", handleClickOutside, true)

    return (
        <div className="item-wrapper">
            <div className='item'>
                <form onSubmit={handleSubmitForm}>
                    <input
                        ref={inputRef}
                        value={inputValue}
                        readOnly={read}
                        onChange={(e) => { handleAnnotationText(e) }}
                        maxLength="100"
                    />
                </form>
                <div className="actions">
                    {
                        !read
                            ? <button className='btn-action-send'>
                                <ArrowUpwardRoundedIcon />
                            </button>
                            : <button
                                className='btn-action-edit'
                                onClick={handleEdit}
                            >
                                <BorderColorRoundedIcon />
                            </button>
                    }
                    <button
                        className='btn-action-delete'
                        onClick={() => { dispatch(deleteAnnotation(id)) }}
                    >
                        <DeleteOutlineRoundedIcon />
                    </button>
                </div>
            </div>
            {annotainError && <p className="error-text">{annotainError}</p>}
        </div>
    )
}

export default AnnotationListItem