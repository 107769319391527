import React, { useEffect, useRef } from 'react'
import './RequestImageUpload.scss'
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useBase64 from '../../hooks/useBase64';
import { useDispatch, useSelector } from 'react-redux';
import { updateSingleProduct } from '../../redux/actions/request';
import SkeletonSingleImage from '../SkeletonSingleImage/SkeletonSingleImage';

const RequestImageUpload = ({ id, images, errors, setError, clearErrors, isSubmitted, ai, setImageLength }) => {
    const fileInput = useRef(null)
    const { imageLoading } = useSelector((state) => state.request)
    const dispatch = useDispatch()
    const { base64Array, handleFileSelect, handleDelete, imageToBase64, open } = useBase64();
    const handleImageUpload = () => {
        fileInput.current.click()
    }

    useEffect(() => {
        if (open) {
            dispatch(updateSingleProduct({ id, field: "images", data: [...base64Array] }))
        }

    }, [base64Array, dispatch, id, open])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { imageToBase64(images) }, [])

    useEffect(() => {
        if (isSubmitted) {
            if (base64Array.length < (ai ? 10 : 3)) {
                setError(`file-${id}`, {
                    type: "minThreeImages",
                });
            } else {
                clearErrors(`file-${id}`)
            }
        }

    }, [base64Array.length, clearErrors, id, isSubmitted, setError, ai])

    useEffect(() => {
        setImageLength(base64Array.length)
    }, [base64Array.length, setImageLength])

    return (
        <div className={`${errors[`file-${id}`] && 'image-error-message'} upload-image-wrapper`}>
            {imageLoading[id]
                ? <div className="images-wrapper">{[1, 2, 3, 4, 5].map(item => <SkeletonSingleImage key={item} />)}</div>
                : base64Array.length > 0
                    ? <div className="images-wrapper">
                        <>
                            {base64Array.map((item, index) => <div key={index} className='item'>
                                <div className="image">
                                    <img src={item} alt="" />
                                </div>
                                <button type="button" onClick={() => handleDelete(index)} className="delete-btn">
                                    <CloseRoundedIcon />
                                </button>
                            </div>)}
                            <div className="add-image">
                                <button
                                    onClick={() => { handleImageUpload() }}
                                    type="button"
                                    className='add-image-btn'
                                >
                                    <BackupRoundedIcon />
                                    <span className='link'>Click to upload</span>
                                </button>
                                <input
                                    ref={fileInput}
                                    type="file"
                                    multiple
                                    onChange={handleFileSelect}
                                />
                            </div>
                        </>
                    </div>
                    : <div className="empty-image">
                        <h4>Add product</h4>
                        <p>{ai ? "Min 10 files, 10 different images, 50 mb size." : "Min 3 file, 50 MB max file size."}</p>
                        <div className="add-image">
                            <button
                                onClick={() => { handleImageUpload() }}
                                type="button"
                                className='add-image-btn'
                            >
                                <BackupRoundedIcon />
                                <span>Upload image</span>
                            </button>
                            <input
                                ref={fileInput}
                                type="file"
                                multiple
                                onChange={handleFileSelect}
                            />
                        </div>
                    </div>}
        </div>
    )
}

export default RequestImageUpload