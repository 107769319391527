import React, { useRef, useState } from 'react'
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import './ModelActions.scss'
import { Box, Modal } from '@mui/material';
import { FacebookShareButton, WhatsappShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from "react-share";
import { FacebookIcon, WhatsappIcon, LinkedinIcon, TwitterIcon, EmailIcon } from "react-share";
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: 'background.paper',
    borderRadius: '6px',
    boxShadow: 24,
    p: 3,
};

const embedStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    bgcolor: 'background.paper',
    borderRadius: '6px',
    boxShadow: 24,
    p: 3,
};

function BasicModal({ active }) {
    const [open, setOpen] = useState(false);
    const inputValue = useRef();
    const notify = (message) => toast(message);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCopy = (input) => {
        navigator.clipboard.writeText(input.current.value);
        notify('Link copied to clipboard.')
    };

    const model_id = useParams().id
    const url = `${window.location.origin}/model/viewer/${model_id}`;

    return (
        <div>
            <button
                onClick={handleOpen}
                className={`${!active && 'deactive'}`}
                disabled={!active}
            >
                <ReplyRoundedIcon />
                <span >Share</span>
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='share-link-wrapper'>
                        <h3 className='title'>Share model on:</h3>
                        <div className='social-media-btns'>
                            <div className='item'>
                                <FacebookShareButton url={url}>
                                    <FacebookIcon size={54} round={true} />
                                </FacebookShareButton>
                                <span>Facebook</span>
                            </div>
                            <div className='item'>
                                <WhatsappShareButton url={url}>
                                    <WhatsappIcon size={54} round={true} />
                                </WhatsappShareButton>
                                <span>WhatsApp</span>
                            </div>
                            <div className='item'>
                                <LinkedinShareButton url={url}>
                                    <LinkedinIcon size={54} round={true} />
                                </LinkedinShareButton>
                                <span>Linkedin</span>
                            </div>
                            <div className='item'>
                                <TwitterShareButton url={url}>
                                    <TwitterIcon size={54} round={true} />
                                </TwitterShareButton>
                                <span>Twitter</span>
                            </div>
                            <div className='item'>
                                <EmailShareButton url={url}>
                                    <EmailIcon size={54} round={true} />
                                </EmailShareButton>
                                <span>Email</span>
                            </div>
                        </div>
                        <div className='copy-link'>
                            <input ref={inputValue} value={url} readOnly />
                            <button onClick={() => { handleCopy(inputValue) }}>copy</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

function EmbedModal({ active, scriptMessage, frameMessage }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const frameValue = useRef()
    const scriptValue = useRef()
    const notify = (message) => toast(message);

    const handleCopy = (input) => {
        navigator.clipboard.writeText(input.current.value);
        notify(`${input.current.name} copied to clipboard.`)
    };

    return (
        <div>
            <button
                onClick={handleOpen}
                className={`${!active && 'deactive'}`}
                disabled={!active}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"> <path d="M4.5 6.1875L1.125 9L4.5 11.8125" stroke={active ? "#A1A8B4" : "#D1D1D1"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M13.5 6.1875L16.875 9L13.5 11.8125" stroke={active ? "#A1A8B4" : "#D1D1D1"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M11.25 2.8125L6.75 15.1875" stroke={active ? "#A1A8B4" : "#D1D1D1"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                <span>Embed</span>
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={embedStyle}>
                    <div className="embed-wrapper">
                        <h3 className='title'>Select code </h3>
                        <div className='iframe-code'>
                            <p>Standard method (recommended)</p>
                            <div className="box">
                                <textarea ref={scriptValue} name="Script" rows="2" defaultValue={scriptMessage} readOnly></textarea>
                                <button onClick={() => { handleCopy(scriptValue) }} className='copy-embed'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M20.25 17.25V3.75H6.75" stroke="#555E6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#555E6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className='iframe-code'>
                            <p>Fallback method (iframe) </p>
                            <div className="box">
                                <textarea ref={frameValue} name="iFrame" rows="2" defaultValue={frameMessage} readOnly></textarea>
                                <button onClick={() => { handleCopy(frameValue) }} className='copy-embed'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M20.25 17.25V3.75H6.75" stroke="#555E6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.25 6.75H3.75V20.25H17.25V6.75Z" stroke="#555E6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

const ModelActions = ({ name, active, model_url, scriptMessage, frameMessage }) => {
    const handleDownloadModel = () => {
        window.location.href = model_url;
    }

    return (
        <>
            <ToastContainer autoClose={1000} hideProgressBar={true} />
            <div className='action-btn-group'>
                <div className='model-name'>
                    <h3>{name}</h3>
                    <p>3D Model</p>
                </div>
                <div className='btn-group'>
                    <EmbedModal active={active} scriptMessage={scriptMessage} frameMessage={frameMessage} />
                    <BasicModal active={active} />
                    <button onClick={handleDownloadModel} className={`${!active && 'deactive'}`}>
                        <GetAppRoundedIcon />
                        <span>Download</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default ModelActions