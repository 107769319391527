import React from 'react'
import './RequestSkeleton.scss'
import ContentLoader from "react-content-loader"

const RequestSkeleton = ({ props }) => {
    return (
        <div className='request-skeleton-wrapper'>
            <ContentLoader
                speed={2}
                width='100%'
                height='240'
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="4" ry="4" width="100%" height="40" />
                <rect x="0" y="60" rx="4" ry="4" width="100%" height="125" />
                <rect x="0" y="205" rx="4" ry="4" width="100%" height="40" />
            </ContentLoader>
        </div>
    )
}

export default RequestSkeleton