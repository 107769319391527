import React from 'react';
import './CheckoutAccardion.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

const orderSummaryFirst = ['Source files', '.glb', 'Metaverse', '.usdz', '.gltf', 'Preview before delivery', 'Google Swril', 'Real World Scale', 'Yahoo Carousel']
const orderSummarySecond = ['View in my room button', 'Web 3D Viewer', 'Managed assets', 'Facebook & Instagram Publishing', 'QR code', 'AR wrapper']
const orderSummaryThird = ['Time for model revision: 3 business days', 'Delivery  in 14 business days']

const CheckoutAccardion = () => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className='checkout-accardion'>
            <Accordion className='accardion-body' expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.92893 10H17.0711" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.3258 4.25476L17.071 10L11.3258 15.7452" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h3 className='title'>Order Summary </h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div className='order-summary-details'>
                        <h3>Available after modelling is finished</h3>
                        <div className='summary-items'>
                            {
                                orderSummaryFirst.map((item, index) => {
                                    return (
                                        <div className='item' key={index}>
                                            <span>{item}</span>
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1_1110)">
                                                    <path d="M6.5 0.5C5.21442 0.5 3.95772 0.881218 2.8888 1.59545C1.81988 2.30968 0.986756 3.32484 0.494786 4.51256C0.00281636 5.70028 -0.125905 7.00721 0.124899 8.26809C0.375703 9.52896 0.994768 10.6872 1.90381 11.5962C2.81285 12.5052 3.97104 13.1243 5.23192 13.3751C6.49279 13.6259 7.79973 13.4972 8.98745 13.0052C10.1752 12.5132 11.1903 11.6801 11.9046 10.6112C12.6188 9.54229 13 8.28558 13 7C12.9981 5.27667 12.3127 3.62445 11.0941 2.40586C9.87556 1.18728 8.22334 0.501864 6.5 0.5V0.5ZM6.5 12.4167C5.42869 12.4167 4.38143 12.099 3.49066 11.5038C2.5999 10.9086 1.90563 10.0626 1.49566 9.07287C1.08568 8.0831 0.978413 6.99399 1.18742 5.94326C1.39642 4.89253 1.91231 3.92737 2.66984 3.16984C3.42738 2.4123 4.39253 1.89642 5.44326 1.68741C6.49399 1.47841 7.58311 1.58568 8.57287 1.99565C9.56264 2.40563 10.4086 3.0999 11.0038 3.99066C11.599 4.88143 11.9167 5.92868 11.9167 7C11.9151 8.43611 11.3439 9.81294 10.3284 10.8284C9.31294 11.8439 7.93611 12.4151 6.5 12.4167Z" fill="#4F8BF0" />
                                                    <path d="M6.50001 5.91663H5.95834C5.81468 5.91663 5.6769 5.97369 5.57532 6.07528C5.47374 6.17686 5.41667 6.31463 5.41667 6.45829C5.41667 6.60195 5.47374 6.73973 5.57532 6.84131C5.6769 6.94289 5.81468 6.99996 5.95834 6.99996H6.50001V10.25C6.50001 10.3936 6.55707 10.5314 6.65866 10.633C6.76024 10.7346 6.89801 10.7916 7.04167 10.7916C7.18533 10.7916 7.32311 10.7346 7.42469 10.633C7.52627 10.5314 7.58334 10.3936 7.58334 10.25V6.99996C7.58334 6.71264 7.4692 6.43709 7.26604 6.23393C7.06287 6.03076 6.78732 5.91663 6.50001 5.91663Z" fill="#4F8BF0" />
                                                    <path d="M6.5 4.83337C6.94873 4.83337 7.3125 4.46961 7.3125 4.02087C7.3125 3.57214 6.94873 3.20837 6.5 3.20837C6.05127 3.20837 5.6875 3.57214 5.6875 4.02087C5.6875 4.46961 6.05127 4.83337 6.5 4.83337Z" fill="#4F8BF0" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1_1110">
                                                        <rect width="13" height="13" fill="white" transform="translate(0 0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='order-summary-details'>
                        <h3>Available after modelling is finished</h3>
                        <div className='summary-items'>
                            {
                                orderSummarySecond.map((item, index) => {
                                    return (
                                        <div className='item' key={index}>
                                            <span>{item}</span>
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1_1110)">
                                                    <path d="M6.5 0.5C5.21442 0.5 3.95772 0.881218 2.8888 1.59545C1.81988 2.30968 0.986756 3.32484 0.494786 4.51256C0.00281636 5.70028 -0.125905 7.00721 0.124899 8.26809C0.375703 9.52896 0.994768 10.6872 1.90381 11.5962C2.81285 12.5052 3.97104 13.1243 5.23192 13.3751C6.49279 13.6259 7.79973 13.4972 8.98745 13.0052C10.1752 12.5132 11.1903 11.6801 11.9046 10.6112C12.6188 9.54229 13 8.28558 13 7C12.9981 5.27667 12.3127 3.62445 11.0941 2.40586C9.87556 1.18728 8.22334 0.501864 6.5 0.5V0.5ZM6.5 12.4167C5.42869 12.4167 4.38143 12.099 3.49066 11.5038C2.5999 10.9086 1.90563 10.0626 1.49566 9.07287C1.08568 8.0831 0.978413 6.99399 1.18742 5.94326C1.39642 4.89253 1.91231 3.92737 2.66984 3.16984C3.42738 2.4123 4.39253 1.89642 5.44326 1.68741C6.49399 1.47841 7.58311 1.58568 8.57287 1.99565C9.56264 2.40563 10.4086 3.0999 11.0038 3.99066C11.599 4.88143 11.9167 5.92868 11.9167 7C11.9151 8.43611 11.3439 9.81294 10.3284 10.8284C9.31294 11.8439 7.93611 12.4151 6.5 12.4167Z" fill="#4F8BF0" />
                                                    <path d="M6.50001 5.91663H5.95834C5.81468 5.91663 5.6769 5.97369 5.57532 6.07528C5.47374 6.17686 5.41667 6.31463 5.41667 6.45829C5.41667 6.60195 5.47374 6.73973 5.57532 6.84131C5.6769 6.94289 5.81468 6.99996 5.95834 6.99996H6.50001V10.25C6.50001 10.3936 6.55707 10.5314 6.65866 10.633C6.76024 10.7346 6.89801 10.7916 7.04167 10.7916C7.18533 10.7916 7.32311 10.7346 7.42469 10.633C7.52627 10.5314 7.58334 10.3936 7.58334 10.25V6.99996C7.58334 6.71264 7.4692 6.43709 7.26604 6.23393C7.06287 6.03076 6.78732 5.91663 6.50001 5.91663Z" fill="#4F8BF0" />
                                                    <path d="M6.5 4.83337C6.94873 4.83337 7.3125 4.46961 7.3125 4.02087C7.3125 3.57214 6.94873 3.20837 6.5 3.20837C6.05127 3.20837 5.6875 3.57214 5.6875 4.02087C5.6875 4.46961 6.05127 4.83337 6.5 4.83337Z" fill="#4F8BF0" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1_1110">
                                                        <rect width="13" height="13" fill="white" transform="translate(0 0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='order-summary-details'>
                        <h3>Delivery time</h3>
                        <div className='summary-items'>
                            {
                                orderSummaryThird.map((item, index) => {
                                    return (
                                        <div className='item' key={index}>
                                            <span>{item}</span>
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_1_1110)">
                                                    <path d="M6.5 0.5C5.21442 0.5 3.95772 0.881218 2.8888 1.59545C1.81988 2.30968 0.986756 3.32484 0.494786 4.51256C0.00281636 5.70028 -0.125905 7.00721 0.124899 8.26809C0.375703 9.52896 0.994768 10.6872 1.90381 11.5962C2.81285 12.5052 3.97104 13.1243 5.23192 13.3751C6.49279 13.6259 7.79973 13.4972 8.98745 13.0052C10.1752 12.5132 11.1903 11.6801 11.9046 10.6112C12.6188 9.54229 13 8.28558 13 7C12.9981 5.27667 12.3127 3.62445 11.0941 2.40586C9.87556 1.18728 8.22334 0.501864 6.5 0.5V0.5ZM6.5 12.4167C5.42869 12.4167 4.38143 12.099 3.49066 11.5038C2.5999 10.9086 1.90563 10.0626 1.49566 9.07287C1.08568 8.0831 0.978413 6.99399 1.18742 5.94326C1.39642 4.89253 1.91231 3.92737 2.66984 3.16984C3.42738 2.4123 4.39253 1.89642 5.44326 1.68741C6.49399 1.47841 7.58311 1.58568 8.57287 1.99565C9.56264 2.40563 10.4086 3.0999 11.0038 3.99066C11.599 4.88143 11.9167 5.92868 11.9167 7C11.9151 8.43611 11.3439 9.81294 10.3284 10.8284C9.31294 11.8439 7.93611 12.4151 6.5 12.4167Z" fill="#4F8BF0" />
                                                    <path d="M6.50001 5.91663H5.95834C5.81468 5.91663 5.6769 5.97369 5.57532 6.07528C5.47374 6.17686 5.41667 6.31463 5.41667 6.45829C5.41667 6.60195 5.47374 6.73973 5.57532 6.84131C5.6769 6.94289 5.81468 6.99996 5.95834 6.99996H6.50001V10.25C6.50001 10.3936 6.55707 10.5314 6.65866 10.633C6.76024 10.7346 6.89801 10.7916 7.04167 10.7916C7.18533 10.7916 7.32311 10.7346 7.42469 10.633C7.52627 10.5314 7.58334 10.3936 7.58334 10.25V6.99996C7.58334 6.71264 7.4692 6.43709 7.26604 6.23393C7.06287 6.03076 6.78732 5.91663 6.50001 5.91663Z" fill="#4F8BF0" />
                                                    <path d="M6.5 4.83337C6.94873 4.83337 7.3125 4.46961 7.3125 4.02087C7.3125 3.57214 6.94873 3.20837 6.5 3.20837C6.05127 3.20837 5.6875 3.57214 5.6875 4.02087C5.6875 4.46961 6.05127 4.83337 6.5 4.83337Z" fill="#4F8BF0" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1_1110">
                                                        <rect width="13" height="13" fill="white" transform="translate(0 0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion className='accardion-body' expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.92893 10H17.0711" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.3258 4.25476L17.071 10L11.3258 15.7452" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h3 className='title'>Return policy</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </p>
                </AccordionDetails>
            </Accordion>
        </div>

    );
}

export default CheckoutAccardion; 