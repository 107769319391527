import React from 'react'
import ContentLoader from "react-content-loader"

const SkeletonSingleImage = ({ props }) => {
    return (
        <div className='item'>
            <ContentLoader
                speed={2}
                width='124'
                height='131'
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="0" y="0" rx="0" ry="0" width="124" height="131" />
            </ContentLoader>
        </div>
    )
}

export default SkeletonSingleImage