import { axiosPrivate } from '../../api/api';
import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

const RegisterSuccess = () => {
    const [searchParams] = useSearchParams()
    const [message, setMessage] = useState()

    useEffect(() => {
        const id = searchParams.get('uidb64');
        const token = searchParams.get('token');

        const getActivated = async () => {
            const result = await axiosPrivate.get(`/api/v1/accounts/activate/${id}/${token}/`)
            setMessage(result.data);
        }

        getActivated()

    }, [searchParams])


    return (
        <div className='success-wrapper'>
            <div className='content'>
                <p>{message?.message}</p>
                <Link to='/login'>Login </Link>
            </div>
        </div>
    )
}

export default RegisterSuccess