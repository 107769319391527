import React from 'react'
import "./ModelDetails.scss"

const ModelDetails = () => {
    return (
        <div className='action-details-wrapper'>
            <ul>
                <li>
                    <button
                        className="active"
                    >
                        Details
                    </button>
                </li>
            </ul>
            <div className='content'>
                <div className='details-content-wrapper'>
                    <div className='left-side'>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>License</div>
                                <div className='result'>Standart</div>
                            </div>
                        </div>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>Included 3D formats</div>
                                <div className='result'>USDZ <span>(usdz)</span></div>
                            </div>
                            <div className='item'>
                                <div className='title'></div>
                                <div className='result'>3ds Max <span>(max)</span></div>
                            </div>
                            <div className='item'>
                                <div className='title'></div>
                                <div className='result'>GLTF <span>(gltf)</span></div>
                            </div>
                            <div className='item'>
                                <div className='title'></div>
                                <div className='result'>USDZ <span>(usdz)</span></div>
                            </div>
                        </div>
                    </div>
                    <div className='right-side'>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>Download size</div>
                                <div className='result'>25Mb</div>
                            </div>
                        </div>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>Extra archives size</div>
                                <div className='result'>5Mb</div>
                            </div>
                        </div>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>UV Mapping</div>
                                <div className='result'>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.1094 8.53125L9.29414 13.125L6.89062 10.8281" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.5 18.375C14.8492 18.375 18.375 14.8492 18.375 10.5C18.375 6.15076 14.8492 2.625 10.5 2.625C6.15076 2.625 2.625 6.15076 2.625 10.5C2.625 14.8492 6.15076 18.375 10.5 18.375Z" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className='item-wrapper'>
                            <div className='item'>
                                <div className='title'>Textures</div>
                                <div className='result'>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.1094 8.53125L9.29414 13.125L6.89062 10.8281" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.5 18.375C14.8492 18.375 18.375 14.8492 18.375 10.5C18.375 6.15076 14.8492 2.625 10.5 2.625C6.15076 2.625 2.625 6.15076 2.625 10.5C2.625 14.8492 6.15076 18.375 10.5 18.375Z" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModelDetails