import React, { useCallback, useEffect, useRef, useState } from 'react'
import './PreviewModel.scss'
import '@google/model-viewer'
import ReactGA from "react-ga4"
import { axiosPublic } from '../../api/api'
import { useParams } from 'react-router-dom'
import useFullscreen from '../../hooks/useFullscreen'
import AnnotationButton from '../../components/AnnotationButton/AnnotationButton'
import sunny from '../../images/sunny.hdr'
import night from '../../images/night.hdr'
import indoor from '../../images/art_studio.hdr'
import outdoor from '../../images/hopper.hdr'
import CustomModal from '../../components/Modal/Modal'
import { io } from 'socket.io-client';
import Svg360 from '../../components/360Svg/360Svg'
import View360 from '../../components/360-view/View360'

const environmentData = {
    sunny,
    night,
    indoor,
    outdoor
}

const PreviewModel = () => {
    const model_id = useParams().id
    const modelViewer = useRef()
    const modelViewerWrapperCurrent = useRef()
    const [modelData, setModelData] = useState(null)
    const [variantName, setVariantName] = useState([])
    const { isFullscreen, toggleFullscreen } = useFullscreen(modelViewerWrapperCurrent.current);
    const [playAnimation, setPlayAnimation] = useState(false)
    const [info, setInfo] = useState(false)
    const [hideAutoLoadButton, setAutoLoadButton] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const [productType, setProductType] = useState('model')
    const [int, setInt] = useState(250)

    const handleOpenModal = () => {
        setShowModal(true);
    };

    useEffect(() => {
        const socket = io.connect(process.env.REACT_APP_ANALYTICS_URL, { query: `uuid=${model_id}&viewer=true` });
        return () => {
            socket.disconnect()
        };
    }, [model_id]);

    // Sending AR button click event
    const sendARButtonClickEvent = () => {
        ReactGA.event('ar_button_click', {
            ar_model_id: `${model_id}`,
        });
    };

    const [dimLines, setDimLines] = useState();
    const [toggle, setToggle] = useState(false)

    const drawLine = useCallback(
        (svgLine, dotHotspot1, dotHotspot2, dimensionHotspot) => {
            if (dotHotspot1 && dotHotspot2) {
                svgLine.setAttribute("x1", dotHotspot1.canvasPosition.x);
                svgLine.setAttribute("y1", dotHotspot1.canvasPosition.y);
                svgLine.setAttribute("x2", dotHotspot2.canvasPosition.x);
                svgLine.setAttribute("y2", dotHotspot2.canvasPosition.y);

                // use provided optional hotspot to tie visibility of this svg line to
                if (dimensionHotspot && !dimensionHotspot.facingCamera) {
                    svgLine.classList.add("hide");
                } else {
                    svgLine.classList.remove("hide");
                }
            }
        },
        []
    );

    const renderSVG = useCallback(() => {
        if (dimLines && productType === "model") {

            drawLine(
                dimLines[0],
                modelViewer?.current?.queryHotspot("hotspot-dot+X-Y+Z"),
                modelViewer?.current?.queryHotspot("hotspot-dot+X-Y-Z"),
                modelViewer?.current?.queryHotspot("hotspot-dim+X-Y")
            );
            drawLine(
                dimLines[1],
                modelViewer?.current?.queryHotspot("hotspot-dot+X-Y-Z"),
                modelViewer?.current?.queryHotspot("hotspot-dot+X+Y-Z"),
                modelViewer?.current?.queryHotspot("hotspot-dim+X-Z")
            );
            drawLine(
                dimLines[2],
                modelViewer?.current?.queryHotspot("hotspot-dot+X+Y-Z"),
                modelViewer?.current?.queryHotspot("hotspot-dot-X+Y-Z")
            ); // always visible
            drawLine(
                dimLines[3],
                modelViewer?.current?.queryHotspot("hotspot-dot-X+Y-Z"),
                modelViewer?.current?.queryHotspot("hotspot-dot-X-Y-Z"),
                modelViewer?.current?.queryHotspot("hotspot-dim-X-Z")
            );
            drawLine(
                dimLines[4],
                modelViewer?.current?.queryHotspot("hotspot-dot-X-Y-Z"),
                modelViewer?.current?.queryHotspot("hotspot-dot-X-Y+Z"),
                modelViewer?.current?.queryHotspot("hotspot-dim-X-Y")
            );
        }
    }, [dimLines, drawLine, modelViewer, productType]);

    useEffect(() => {
        const dimLines = modelViewer?.current?.querySelectorAll("line");
        setDimLines(dimLines);
    }, [productType]);

    useEffect(() => {
        modelViewer?.current?.addEventListener("camera-change", () => {
            if (dimLines) {
                renderSVG();
            }
        });
    }, [dimLines, renderSVG, productType]);

    useEffect(() => {
        modelViewer?.current?.addEventListener("auto-rotate", () => {
            if (dimLines) {
                renderSVG();
            }
        });
    }, [dimLines, renderSVG, productType]);

    useEffect(() => {
        modelViewer?.current?.addEventListener("load", () => {
            if (modelViewer?.current) {
                const center = modelViewer.current.getBoundingBoxCenter();
                const size = modelViewer.current.getDimensions();
                const x2 = size.x / 2;
                const y2 = size.y / 2;
                const z2 = size.z / 2;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot+X-Y+Z",
                    position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim+X-Y",
                    position: `${center.x + x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim+X-Y"]'
                ).textContent = `${(size.z * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot+X-Y-Z",
                    position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim+X-Z",
                    position: `${center.x + x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim+X-Z"]'
                ).textContent = `${(size.y * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot+X+Y-Z",
                    position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim+Y-Z",
                    position: `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim+Y-Z"]'
                ).textContent = `${(size.x * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot-X+Y-Z",
                    position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim-X-Z",
                    position: `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim-X-Z"]'
                ).textContent = `${(size.y * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot-X-Y-Z",
                    position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`,
                });

                modelViewer.current.updateHotspot({
                    name: "hotspot-dim-X-Y",
                    position: `${center.x - x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`,
                });
                modelViewer.current.querySelector(
                    'button[slot="hotspot-dim-X-Y"]'
                ).textContent = `${(size.z * 100).toFixed(0)} cm`;

                modelViewer.current.updateHotspot({
                    name: "hotspot-dot-X-Y+Z",
                    position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`,
                });

                if (drawLine) {
                    renderSVG();
                }
            }
        });
    }, [drawLine, renderSVG, productType]);

    // * Fetch Model Data
    useEffect(() => {
        const getData = async id => {
            setLoading(true)
            try {
                const result = await axiosPublic.get(`/api/v1/models/viewer/${id}/`)
                setModelData(result.data);
            } catch (error) {
                console.log(error);
            }
            setLoading(false)
        }

        getData(model_id)
    }, [model_id])

    // * Set variant default
    useEffect(() => {
        const newVariant = modelData?.variants?.map(item => {
            const activeOption = item.options.find((opt) => {
                return opt.name
            })

            return {
                name: item.name,
                option: activeOption?.name
            }
        })

        setVariantName(newVariant)
    }, [modelData?.variants])

    // * Handle variant select
    const handleVariant = (option, name) => {
        const newVariant = variantName?.map(item => {
            if (item.name === name) {
                return {
                    name: item.name,
                    option,
                }
            } else return item
        })

        setVariantName(newVariant)
    }

    // * Set variant when change variant name
    useEffect(() => {
        modelViewer.current.variantName = variantName?.map(item => `${item.name}:${item.option}`).join('_');
    }, [variantName])

    // * Play model animation
    useEffect(() => {
        if (!playAnimation) {
            modelViewer.current.pause()
        }
    }, [playAnimation])

    // * Handle Autoload Button
    const handleAutoLoadButton = () => {
        modelViewer.current.dismissPoster()
        setAutoLoadButton(false)
    }

    useEffect(() => {
        function interpolationModel(m) {
            const orbitCycle = [m.cameraOrbit];

            const timeoutId = setTimeout(() => {
                m.cameraOrbit =
                    orbitCycle[
                    ("45deg 0deg 2.5m", "90deg 0deg 2.5m", "135deg 0deg 2.5m")
                    ];
            }, 500);

            return () => {
                clearTimeout(timeoutId);
            };
        }

        interpolationModel(modelViewer.current);

        setTimeout(() => {
            setInt(150)
        }, 1500);
    }, []);

    const handleDimensions = () => {
        if (!toggle && modelData?.is_autorotate) {
            modelViewer.current.removeAttribute('auto-rotate');
        } else if (toggle && modelData?.is_autorotate) {
            modelViewer.current.setAttribute('auto-rotate', true);
        }
        setToggle(!toggle)
        if (dimLines) {
            renderSVG();
        }
    }

    return (
        <div ref={modelViewerWrapperCurrent} className='preview-model-wrapper' style={{ backgroundColor: modelData?.background_color }}>
            <CustomModal showModal={showModal} setShowModal={setShowModal} modelID={model_id} />
            <div className="model-viewer-wrapper">
                {
                    productType === 'model'
                        ? <model-viewer
                            ref={modelViewer}
                            src={!hideAutoLoadButton || modelData?.is_autoload ? modelData?.file : null}
                            {...(modelData?.usdz && { "ios-src": modelData.usdz })}
                            poster={modelData?.image}
                            touch-action="pan-y"
                            alt="A 3D model carousel"
                            ar
                            camera-controls
                            loading="eager"
                            ar-scale="fixed"
                            shadow-intensity="1"
                            disable-tap
                            interpolation-decay={int}
                            camera-orbit="125deg 55deg 4m"
                            max-camera-orbit="auto 100deg auto"
                            auto-rotate-delay="1000"
                            rotation-per-second="20deg"
                            reveal={modelData?.is_autoload ? 'auto' : 'manual'}
                            environment-image={modelData?.environment ? environmentData[modelData?.environment] : null}
                            {...(playAnimation && { "autoplay": "autoplay" })}
                            {...(modelData?.is_autorotate && { "auto-rotate": "auto-rotate" })}
                        >
                            <button
                                slot="hotspot-dot+X-Y+Z"
                                className={`dot ${!toggle ? "hide" : null}`}
                                data-position="1 -1 1"
                                data-normal="1 0 0"
                            ></button>
                            <button
                                slot="hotspot-dim+X-Y"
                                className={`dim ${!toggle ? "hide" : null}`}
                                data-position="1 -1 0"
                                data-normal="1 0 0"
                            ></button>
                            <button
                                slot="hotspot-dot+X-Y-Z"
                                className={`dot ${!toggle ? "hide" : null}`}
                                data-position="1 -1 -1"
                                data-normal="1 0 0"
                            ></button>
                            <button
                                slot="hotspot-dim+X-Z"
                                className={`dim ${!toggle ? "hide" : null}`}
                                data-position="1 0 -1"
                                data-normal="1 0 0"
                            ></button>
                            <button
                                slot="hotspot-dot+X+Y-Z"
                                className={`dot ${!toggle ? "hide" : null}`}
                                data-position="1 1 -1"
                                data-normal="0 1 0"
                            ></button>
                            <button
                                slot="hotspot-dim+Y-Z"
                                className={`dim ${!toggle ? "hide" : null}`}
                                data-position="0 -1 -1"
                                data-normal="0 1 0"
                            ></button>
                            <button
                                slot="hotspot-dot-X+Y-Z"
                                className={`dot ${!toggle ? "hide" : null}`}
                                data-position="-1 1 -1"
                                data-normal="0 1 0"
                            ></button>
                            <button
                                slot="hotspot-dim-X-Z"
                                className={`dim ${!toggle ? "hide" : null}`}
                                data-position="-1 0 -1"
                                data-normal="-1 0 0"
                            ></button>
                            <button
                                slot="hotspot-dot-X-Y-Z"
                                className={`dot ${!toggle ? "hide" : null}`}
                                data-position="-1 -1 -1"
                                data-normal="-1 0 0"
                            ></button>
                            <button
                                slot="hotspot-dim-X-Y"
                                className={`dim ${!toggle ? "hide" : null}`}
                                data-position="-1 -1 0"
                                data-normal="-1 0 0"
                            ></button>
                            <button
                                slot="hotspot-dot-X-Y+Z"
                                className={`dot ${!toggle ? "hide" : null}`}
                                data-position="-1 -1 1"
                                data-normal="-1 0 0"
                            ></button>
                            <svg
                                id="dimLines"
                                width="100%"
                                height="100%"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`dimensionLineContainer ${!toggle ? "hide" : null}`}
                            >
                                <line className="dimensionLine"></line>
                                <line className="dimensionLine"></line>
                                <line className="dimensionLine"></line>
                                <line className="dimensionLine"></line>
                                <line className="dimensionLine"></line>
                            </svg>

                            {
                                info && modelData?.annotations?.map(item => <AnnotationButton
                                    key={item.id}
                                    id={item.id}
                                    text={item.text}
                                    position={Object.values(item.data_position.position).join(" ")}
                                    normal={Object.values(item.data_position.normal).join(" ")}
                                />)
                            }

                            {modelData?.variants?.length > 0 && (!hideAutoLoadButton || modelData?.is_autoload) && <div className="variants-ui">
                                {modelData?.variants?.map(variant =>
                                    <div key={variant.name} className="item">
                                        <p>{variant.name}</p>
                                        <div className="select-wrapper">
                                            <select
                                                id={variant.name}
                                                onChange={(e) => { handleVariant(e.target.value, variant.name) }}
                                            >
                                                {variant.options.map(option => {
                                                    return <option key={option.id} value={option.name} >{option.name}</option>
                                                })}
                                            </select>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13 6L8 11L3 6" stroke="#555E6D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>)}
                            </div>}

                            <button slot="ar-button" className='btn-view-ar' onClick={sendARButtonClickEvent}>
                                <span>View in your space</span>
                            </button>
                            <div className='view-your-space-wrapper'>
                                <button className='btn-view' onClick={handleOpenModal}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_815_32598)">
                                            <path d="M8.25 14.6625L4.5 12.5062C4.2625 12.3687 4.07825 12.1845 3.94725 11.9535C3.81575 11.722 3.75 11.4688 3.75 11.1938V6.9C3.75 6.625 3.81575 6.37175 3.94725 6.14025C4.07825 5.90925 4.2625 5.725 4.5 5.5875L8.25 3.43125C8.4875 3.29375 8.7375 3.225 9 3.225C9.2625 3.225 9.5125 3.29375 9.75 3.43125L13.5 5.5875C13.7375 5.725 13.922 5.90925 14.0535 6.14025C14.1845 6.37175 14.25 6.625 14.25 6.9V11.1938C14.25 11.4688 14.1845 11.722 14.0535 11.9535C13.922 12.1845 13.7375 12.3687 13.5 12.5062L9.75 14.6625C9.5125 14.8 9.2625 14.8688 9 14.8688C8.7375 14.8688 8.4875 14.8 8.25 14.6625ZM8.25 12.9375V9.4875L5.25 7.7625V11.2125L8.25 12.9375ZM9.75 12.9375L12.75 11.2125V7.7625L9.75 9.4875V12.9375ZM0.75 4.5V3C0.75 2.375 0.96875 1.84375 1.40625 1.40625C1.84375 0.96875 2.375 0.75 3 0.75H4.5V2.25H3C2.7875 2.25 2.6095 2.32175 2.466 2.46525C2.322 2.60925 2.25 2.7875 2.25 3V4.5H0.75ZM3 17.25C2.375 17.25 1.84375 17.0312 1.40625 16.5938C0.96875 16.1562 0.75 15.625 0.75 15V13.5H2.25V15C2.25 15.2125 2.322 15.3905 2.466 15.534C2.6095 15.678 2.7875 15.75 3 15.75H4.5V17.25H3ZM13.5 17.25V15.75H15C15.2125 15.75 15.3905 15.678 15.534 15.534C15.678 15.3905 15.75 15.2125 15.75 15V13.5H17.25V15C17.25 15.625 17.0312 16.1562 16.5938 16.5938C16.1562 17.0312 15.625 17.25 15 17.25H13.5ZM15.75 4.5V3C15.75 2.7875 15.678 2.60925 15.534 2.46525C15.3905 2.32175 15.2125 2.25 15 2.25H13.5V0.75H15C15.625 0.75 16.1562 0.96875 16.5938 1.40625C17.0312 1.84375 17.25 2.375 17.25 3V4.5H15.75ZM9 8.175L12 6.45L9 4.725L6 6.45L9 8.175Z" fill="#4F8BF0" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_815_32598">
                                                <rect width="18" height="18" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>View in your space</span>
                                </button>
                            </div>

                            {!loading && !modelData?.is_autoload && hideAutoLoadButton ? <button onClick={handleAutoLoadButton} className='autoload-btn'>Load 3D Model</button> : null}
                        </model-viewer>
                        : <>
                            <View360 data={modelData?.images_360} id={model_id} />
                            <div className='view-your-space-wrapper'>
                                <button className='btn-view' onClick={handleOpenModal}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_815_32598)">
                                            <path d="M8.25 14.6625L4.5 12.5062C4.2625 12.3687 4.07825 12.1845 3.94725 11.9535C3.81575 11.722 3.75 11.4688 3.75 11.1938V6.9C3.75 6.625 3.81575 6.37175 3.94725 6.14025C4.07825 5.90925 4.2625 5.725 4.5 5.5875L8.25 3.43125C8.4875 3.29375 8.7375 3.225 9 3.225C9.2625 3.225 9.5125 3.29375 9.75 3.43125L13.5 5.5875C13.7375 5.725 13.922 5.90925 14.0535 6.14025C14.1845 6.37175 14.25 6.625 14.25 6.9V11.1938C14.25 11.4688 14.1845 11.722 14.0535 11.9535C13.922 12.1845 13.7375 12.3687 13.5 12.5062L9.75 14.6625C9.5125 14.8 9.2625 14.8688 9 14.8688C8.7375 14.8688 8.4875 14.8 8.25 14.6625ZM8.25 12.9375V9.4875L5.25 7.7625V11.2125L8.25 12.9375ZM9.75 12.9375L12.75 11.2125V7.7625L9.75 9.4875V12.9375ZM0.75 4.5V3C0.75 2.375 0.96875 1.84375 1.40625 1.40625C1.84375 0.96875 2.375 0.75 3 0.75H4.5V2.25H3C2.7875 2.25 2.6095 2.32175 2.466 2.46525C2.322 2.60925 2.25 2.7875 2.25 3V4.5H0.75ZM3 17.25C2.375 17.25 1.84375 17.0312 1.40625 16.5938C0.96875 16.1562 0.75 15.625 0.75 15V13.5H2.25V15C2.25 15.2125 2.322 15.3905 2.466 15.534C2.6095 15.678 2.7875 15.75 3 15.75H4.5V17.25H3ZM13.5 17.25V15.75H15C15.2125 15.75 15.3905 15.678 15.534 15.534C15.678 15.3905 15.75 15.2125 15.75 15V13.5H17.25V15C17.25 15.625 17.0312 16.1562 16.5938 16.5938C16.1562 17.0312 15.625 17.25 15 17.25H13.5ZM15.75 4.5V3C15.75 2.7875 15.678 2.60925 15.534 2.46525C15.3905 2.32175 15.2125 2.25 15 2.25H13.5V0.75H15C15.625 0.75 16.1562 0.96875 16.5938 1.40625C17.0312 1.84375 17.25 2.375 17.25 3V4.5H15.75ZM9 8.175L12 6.45L9 4.725L6 6.45L9 8.175Z" fill="#4F8BF0" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_815_32598">
                                                <rect width="18" height="18" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>View in your space</span>
                                </button>
                            </div>
                        </>
                }
                {
                    modelData?.images_360 && <>
                        <div className='product-type-wrapper'>
                            <button className={productType === 'model' ? 'active-product-type' : ''} onClick={(e) => { setProductType(e.target.name) }} name='model'>
                                <Svg360 type="model" color={productType === 'model' ? "white" : '#A1A8B4'} />
                            </button>
                            <button className={productType === '360' ? 'active-product-type' : ''} onClick={(e) => { setProductType(e.target.name) }} name='360'>
                                <Svg360 type="360" color={productType === '360' ? "white" : '#A1A8B4'} />
                            </button>
                        </div>
                    </>
                }
                {(!hideAutoLoadButton || modelData?.is_autoload) && <div className='configurator-left-side'>
                    {
                        productType === "model" && <>
                            <button className={`configurator-btn ${toggle ? 'active' : null}`} onClick={() => { handleDimensions() }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path d="M13.8333 12.6667V15.4167M8.33333 15.4167V12.6667H5.58333M8.33333 7.16667H5.58333M1 2.21667V19.45C1 19.5959 1.05795 19.7358 1.16109 19.8389C1.26424 19.9421 1.40413 20 1.55 20H18.7833C18.9292 20 19.0691 19.9421 19.1722 19.8389C19.2754 19.7358 19.3333 19.5959 19.3333 19.45V13.2167C19.3333 13.0708 19.2754 12.9309 19.1722 12.8278C19.0691 12.7246 18.9292 12.6667 18.7833 12.6667H8.88333C8.73746 12.6667 8.59757 12.6087 8.49442 12.5056C8.39128 12.4024 8.33333 12.2625 8.33333 12.1167V2.21667C8.33333 2.14444 8.31911 2.07292 8.29147 2.00619C8.26383 1.93946 8.22331 1.87883 8.17224 1.82776C8.12117 1.77668 8.06054 1.73617 7.99381 1.70853C7.92708 1.68089 7.85556 1.66667 7.78333 1.66667H1.55C1.47777 1.66667 1.40625 1.68089 1.33952 1.70853C1.2728 1.73617 1.21216 1.77668 1.16109 1.82776C1.11002 1.87883 1.06951 1.93946 1.04187 2.00619C1.01423 2.07292 1 2.14444 1 2.21667Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            {modelData?.is_animate && <button onClick={() => { setPlayAnimation(!playAnimation) }} className={`configurator-btn ${playAnimation ? 'active' : null}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z" stroke="#7E899B" strokeWidth="2" strokeMiterlimit="10" />
                                    <path d="M17.5 14L12.25 10.5V17.5L17.5 14Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>}
                            {modelData?.annotations?.length > 0 && <button onClick={() => { setInfo(!info) }} className={`configurator-btn ${info ? 'active' : null}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M14.4376 23.625H5.21726C5.10626 23.6265 4.99608 23.6057 4.89324 23.5639C4.79039 23.5221 4.69697 23.4601 4.61847 23.3816C4.53997 23.3031 4.47799 23.2097 4.43619 23.1068C4.39438 23.004 4.3736 22.8938 4.37507 22.7828V13.5625C4.37507 10.8938 5.43523 8.33432 7.32231 6.44724C9.2094 4.56015 11.7688 3.5 14.4376 3.5C15.759 3.5 17.0675 3.76027 18.2883 4.26596C19.5092 4.77165 20.6184 5.51285 21.5528 6.44724C22.4872 7.38163 23.2284 8.49091 23.7341 9.71175C24.2398 10.9326 24.5001 12.2411 24.5001 13.5625C24.5001 14.8839 24.2398 16.1924 23.7341 17.4133C23.2284 18.6341 22.4872 19.7434 21.5528 20.6778C20.6184 21.6121 19.5092 22.3533 18.2883 22.859C17.0675 23.3647 15.759 23.625 14.4376 23.625Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>}
                        </>
                    }
                    <button onClick={toggleFullscreen} id="fullscreenBtn" className={`configurator-btn ${isFullscreen ? 'active' : null}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M22.75 17.5L22.75 22.75L17.5 22.75" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16.625 16.625L22.75 22.75" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.25 10.5L5.25 5.25L10.5 5.25" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.375 11.375L5.25 5.25" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>}

            </div>
        </div>
    )
}

export default PreviewModel