import { useState } from 'react';

function useTabs(initialTab, tabs) {
    const [activeTab, setActiveTab] = useState(initialTab);

    const handleTabChange = tab => {
        setActiveTab(tab);
    };

    return {
        activeTab,
        onTabChange: handleTabChange
    };
}

export default useTabs