import React, { useEffect, useState } from 'react'
import person from '../../images/register.jpg'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../redux/actions/user'
import { Controller, useForm } from 'react-hook-form'
import { clearError } from '../../redux/reducers/userReducer'
import { CircularProgress } from '@mui/material'
import { axiosPublic } from '../../api/api'

const Login = () => {
    const [passwordShow, setPasswordShow] = useState(false)
    const dispatch = useDispatch();
    const { user, error, loading } = useSelector((state) => state.user);
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        dispatch(getUser(data));
    };

    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            navigate("/");
        }
    }, [navigate, user]);

    useEffect(() => {
        return () => {
            dispatch(clearError());
        };
    }, [dispatch]);

    const handleSocialLogin = (authProvider) => {
        const redirect = async () => {
            try {
                const res = await axiosPublic.get(`/social-auth/o/${authProvider}2?redirect_uri=${process.env.REACT_APP_SITE_URL}auth/verify`, {
                    withCredentials: true,
                })
                localStorage.setItem('authProvider', authProvider);
                window.location.href = res.data.authorization_url;
            } catch (error) {
                console.log(error.response.data);
            }
        }

        redirect()
    }

    return (
        <>
            <header className='register-header'>
                <div className="logo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="23" viewBox="0 0 110 23" fill="none">
                        <path d="M37.7626 17.9883V5.28406H40.7553V17.9883H37.7626ZM39.2674 3.48098C38.7933 3.48098 38.3854 3.32382 38.0437 3.00953C37.7021 2.68971 37.5312 2.30649 37.5312 1.85986C37.5312 1.40771 37.7021 1.02449 38.0437 0.710186C38.3854 0.390375 38.7933 0.230469 39.2674 0.230469C39.7467 0.230469 40.1547 0.390375 40.4908 0.710186C40.8324 1.02449 41.0035 1.40771 41.0035 1.85986C41.0035 2.30649 40.8324 2.68971 40.4908 3.00953C40.1547 3.32382 39.7467 3.48098 39.2674 3.48098Z" fill="#313638" />
                        <path d="M42.8984 17.9869V5.28261H45.7587V7.44132H45.9074C46.1723 6.71347 46.6102 6.14555 47.2222 5.73752C47.8339 5.32395 48.564 5.11719 49.4129 5.11719C50.2725 5.11719 50.9974 5.32672 51.5873 5.74579C52.1824 6.15932 52.6011 6.72451 52.8438 7.44132H52.976C53.2571 6.73554 53.7311 6.17312 54.3981 5.75405C55.0703 5.32949 55.8668 5.11719 56.7872 5.11719C57.9556 5.11719 58.9093 5.48661 59.6475 6.2255C60.3861 6.96438 60.7554 8.04235 60.7554 9.45947V17.9869H57.7546V9.92264C57.7546 9.13412 57.545 8.55793 57.1263 8.19401C56.7072 7.82455 56.1948 7.63985 55.5886 7.63985C54.8663 7.63985 54.3015 7.86592 53.8938 8.31806C53.4913 8.7647 53.2902 9.34642 53.2902 10.0632V17.9869H50.3554V9.79856C50.3554 9.14239 50.157 8.61857 49.7601 8.22708C49.3687 7.83558 48.8562 7.63985 48.2224 7.63985C47.7926 7.63985 47.4012 7.75011 47.0485 7.97067C46.6958 8.18574 46.4147 8.49175 46.2051 8.88875C45.9959 9.28025 45.8911 9.73792 45.8911 10.2617V17.9869H42.8984Z" fill="#313638" />
                        <path d="M66.5149 18.2433C65.7103 18.2433 64.9857 18.0999 64.3408 17.8132C63.7014 17.5209 63.1942 17.0908 62.8194 16.5229C62.4501 15.955 62.2656 15.2547 62.2656 14.4221C62.2656 13.7052 62.398 13.1125 62.6626 12.6438C62.9271 12.1751 63.2879 11.8001 63.7454 11.5189C64.2028 11.2377 64.7182 11.0255 65.2915 10.8821C65.87 10.7332 66.468 10.6257 67.0853 10.5595C67.8294 10.4823 68.4329 10.4134 68.8959 10.3527C69.3589 10.2866 69.695 10.1873 69.9045 10.055C70.1196 9.91711 70.2269 9.70484 70.2269 9.4181V9.36849C70.2269 8.74539 70.0422 8.26292 69.6732 7.92106C69.3039 7.57918 68.7719 7.40825 68.0776 7.40825C67.3445 7.40825 66.7631 7.56817 66.333 7.88796C65.9087 8.20778 65.6221 8.5855 65.4734 9.0211L62.6789 8.62408C62.8994 7.85212 63.2632 7.20699 63.7701 6.68867C64.2773 6.16485 64.8975 5.77336 65.6302 5.51419C66.3633 5.24951 67.1737 5.11719 68.061 5.11719C68.6727 5.11719 69.2818 5.18887 69.8879 5.33222C70.4944 5.47558 71.0482 5.71268 71.5496 6.04353C72.0513 6.36885 72.4534 6.81275 72.7567 7.37518C73.0654 7.9376 73.2196 8.64064 73.2196 9.48428V17.9869H70.3428V16.2417H70.2435C70.0617 16.5946 69.8053 16.9254 69.4747 17.2342C69.1494 17.5375 68.7387 17.7829 68.2429 17.9703C67.7522 18.1523 67.1763 18.2433 66.5149 18.2433ZM67.2922 16.0432C67.8928 16.0432 68.4137 15.9246 68.8546 15.6875C69.2954 15.4449 69.6345 15.1251 69.8713 14.7281C70.114 14.3311 70.2351 13.8982 70.2351 13.4295V11.9325C70.1414 12.0097 69.9816 12.0814 69.7558 12.1475C69.5352 12.2137 69.2873 12.2716 69.0117 12.3212C68.7361 12.3709 68.4632 12.415 68.1931 12.4536C67.9231 12.4922 67.6888 12.5253 67.4906 12.5528C67.0439 12.6135 66.6444 12.7127 66.2917 12.8506C65.939 12.9884 65.6608 13.1814 65.4568 13.4295C65.2528 13.6722 65.1509 13.9865 65.1509 14.3724C65.1509 14.9238 65.352 15.3401 65.7545 15.6213C66.1567 15.9026 66.6694 16.0432 67.2922 16.0432Z" fill="#313638" />
                        <path d="M75.2995 5.28406H78.2922V18.7161C78.2922 19.6315 78.1184 20.3869 77.7713 20.9824C77.4241 21.5779 76.9227 22.0218 76.2668 22.314C75.6108 22.6063 74.8144 22.7524 73.8776 22.7524C73.7673 22.7524 73.6652 22.7496 73.5715 22.7441C73.4778 22.7441 73.3759 22.7414 73.2656 22.7359V20.2959C73.3483 20.3014 73.4228 20.3042 73.4888 20.3042C73.5552 20.3097 73.6239 20.3125 73.6957 20.3125C74.2687 20.3125 74.6793 20.1718 74.9276 19.8906C75.1755 19.615 75.2995 19.2097 75.2995 18.6748V5.28406ZM76.7877 3.48098C76.3081 3.48098 75.8975 3.32382 75.5558 3.00953C75.2194 2.68971 75.0515 2.30649 75.0515 1.85986C75.0515 1.40771 75.2194 1.02449 75.5558 0.710186C75.8975 0.390375 76.3081 0.230469 76.7877 0.230469C77.2614 0.230469 77.6665 0.390375 78.0029 0.710186C78.3445 1.02449 78.5154 1.40771 78.5154 1.85986C78.5154 2.30649 78.3445 2.68971 78.0029 3.00953C77.6665 3.32382 77.2614 3.48098 76.7877 3.48098Z" fill="#313638" />
                        <path d="M80.4423 17.9883V5.28406H83.435V17.9883H80.4423ZM81.9471 3.48098C81.473 3.48098 81.065 3.32382 80.7234 3.00953C80.3818 2.68971 80.2109 2.30649 80.2109 1.85986C80.2109 1.40771 80.3818 1.02449 80.7234 0.710186C81.065 0.390375 81.473 0.230469 81.9471 0.230469C82.4267 0.230469 82.8343 0.390375 83.1705 0.710186C83.5124 1.02449 83.6832 1.40771 83.6832 1.85986C83.6832 2.30649 83.5124 2.68971 83.1705 3.00953C82.8343 3.32382 82.4267 3.48098 81.9471 3.48098Z" fill="#313638" />
                        <path d="M88.5708 10.543V17.9869H85.5781V5.28261H88.4384V7.44132H88.5874C88.8793 6.73004 89.3452 6.16485 89.9845 5.74579C90.6294 5.32672 91.4256 5.11719 92.3737 5.11719C93.2499 5.11719 94.0132 5.30465 94.6636 5.67961C95.3195 6.05457 95.8268 6.59769 96.185 7.309C96.5487 8.02031 96.7277 8.88325 96.7222 9.89781V17.9869H93.7295V10.361C93.7295 9.51185 93.5089 8.84741 93.068 8.3677C92.6327 7.88796 92.0291 7.64812 91.2577 7.64812C90.7342 7.64812 90.2683 7.76391 89.8606 7.99551C89.4581 8.22158 89.1412 8.54966 88.9098 8.97973C88.6837 9.40983 88.5708 9.93091 88.5708 10.543Z" fill="#313638" />
                        <path d="M29.0649 18.0282V6.27314C29.0649 3.05581 27.2548 -0.554257 23.2421 0.44764C19.2295 1.44954 16.7275 6.92896 15.8101 8.13803C15.4671 8.34584 15.5878 10.5435 15.2266 10.7902L16.3327 17.4764C20.3852 14.0804 23.2421 5.51559 23.2421 9.32657V18.0282H29.0649Z" fill="url(#paint0_linear_1917_14080)" />
                        <path d="M11.7434 15.117V9.32814C11.6112 6.88057 8.99236 6.05047 6.07282 8.22409L0.25 12.6301V6.41841C2.56566 4.78431 4.41217 3.28572 6.07282 2.27936C8.20121 0.989529 9.95329 0.449219 11.7434 0.449219C14.9592 0.449219 17.5662 3.05738 17.5662 6.2747V12.1508C17.5662 13.8974 17.2372 16.637 16.706 17.1712C16.1797 17.7004 15.4549 18.0298 14.6547 18.0298C13.0468 18.0298 11.7434 16.7257 11.7434 15.117Z" fill="url(#paint1_linear_1917_14080)" />
                        <path d="M17.5711 12.1484C17.5711 13.895 17.2422 16.6346 16.7109 17.1688C17.4693 16.5016 18.2739 15.4288 19.3639 13.8927C18.5108 14.5095 17.5686 14.5595 17.5711 12.1484Z" fill="url(#paint2_linear_1917_14080)" />
                        <path d="M6.07282 0.269531H0.25V6.41599C2.56566 4.78189 4.41217 3.28331 6.07282 2.27693V0.269531Z" fill="url(#paint3_linear_1917_14080)" />
                        <path d="M0.25 18.0284H6.07282V8.22266L0.25 12.6286V18.0284Z" fill="url(#paint4_linear_1917_14080)" />
                        <defs>
                            <linearGradient id="paint0_linear_1917_14080" x1="15.2035" y1="9.6045" x2="26.0746" y2="18.0446" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2B6CEE" />
                                <stop offset="0.5" stopColor="#2790F9" />
                                <stop offset="1" stopColor="#2BBBF7" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_1917_14080" x1="-0.768805" y1="9.82165" x2="15.7251" y2="13.46" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2BBBF7" />
                                <stop offset="0.494792" stopColor="#2790F9" />
                                <stop offset="1" stopColor="#2BBBF7" />
                            </linearGradient>
                            <linearGradient id="paint2_linear_1917_14080" x1="-0.763819" y1="9.8193" x2="15.7301" y2="13.4577" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2BBBF7" />
                                <stop offset="0.494792" stopColor="#2790F9" />
                                <stop offset="1" stopColor="#2BBBF7" />
                            </linearGradient>
                            <linearGradient id="paint3_linear_1917_14080" x1="3.1614" y1="0.269531" x2="3.1614" y2="18.0274" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2B6CEE" />
                                <stop offset="0.515625" stopColor="#2790F9" />
                                <stop offset="1" stopColor="#2BBBF7" />
                            </linearGradient>
                            <linearGradient id="paint4_linear_1917_14080" x1="3.1614" y1="0.270519" x2="3.1614" y2="18.0284" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#2B6CEE" />
                                <stop offset="0.515625" stopColor="#2790F9" />
                                <stop offset="1" stopColor="#2BBBF7" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <Link to='/register' tabIndex="0" className='go-to-login'>Sign up</Link>
            </header>
            <section className='register-wrapper'>
                <div className="testimonials-wrapper">
                    <div className="image">
                        <img src={person} alt="person" />
                    </div>
                    <div className="testimonials">
                        <div className="title">
                            <h3>Jhon Povel</h3>
                            <h4>Co-Founder at Skyfall company </h4>
                        </div>
                        <p>Before discovering Imajinn, we looked for different solutions that would allow all our brands to achieve the highest quality 3D capturing at scale without technical knowledge and complicated hardware - but with no success. Imajinn exceeded our original expectations by increasing our conversion rates and lowering product returns. Fashion brands at Skyfall  love using Imajinn.</p>
                    </div>
                </div>
                <div className="form-wrapper">
                    <div className="title">
                        <h3>Welcome back!</h3>
                        <p>Free forever. No credit card needed.</p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-group">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address',
                                    },
                                }}
                                render={({ field }) => (
                                    <input type="text" placeholder="Email" {...field} />
                                )}
                            />
                            <div className="error-text">{errors.email && errors.email.message}</div>
                        </div>
                        <div className="input-group">
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'Password is required' }}
                                render={({ field }) => (
                                    <div className='input-showpassword'>
                                        <input type={passwordShow === false ? "password" : "text"} placeholder="Password" {...field} />
                                        <button type='button' onClick={() => { setPasswordShow(!passwordShow) }}>
                                            {passwordShow
                                                ? <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path d="M10.5 4.59375C3.9375 4.59375 1.3125 10.5 1.3125 10.5C1.3125 10.5 3.9375 16.4062 10.5 16.4062C17.0625 16.4062 19.6875 10.5 19.6875 10.5C19.6875 10.5 17.0625 4.59375 10.5 4.59375Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.5 13.7812C12.3122 13.7812 13.7812 12.3122 13.7812 10.5C13.7812 8.68782 12.3122 7.21875 10.5 7.21875C8.68782 7.21875 7.21875 8.68782 7.21875 10.5C7.21875 12.3122 8.68782 13.7812 10.5 13.7812Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path d="M16.4961 10.4424L18.3746 13.6826" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.6494 12.2471L13.2318 15.5529" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M8.34219 12.2393L7.75977 15.5533" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M4.4957 10.4424L2.61719 13.699" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M2.625 8.60547C4.00312 10.3117 6.52969 12.4691 10.5 12.4691C14.4703 12.4691 16.9969 10.3117 18.375 8.60547" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>}
                                        </button>
                                    </div>
                                )}
                            />
                            <div className="error-text login-error-forgot-password">
                                <div>
                                    {errors.password && errors.password.message}
                                    {error && error?.detail}
                                </div>
                                <Link to="/reset-password" className='forget-password'>Forgot password?</Link>
                            </div>
                        </div>

                        {
                            loading
                                ? <button className='register-btn loading-btn'><CircularProgress color="inherit" /></button>
                                : <button className="register-btn">Continue with email</button>
                        }

                    </form>
                    <div className="social-media-register">
                        <button onClick={() => { handleSocialLogin('linkedin-oauth') }} className='social-media-register-btn linkedin'>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.08203 5.48981C4.08203 4.71231 4.71231 4.08203 5.48981 4.08203H22.5076C23.2851 4.08203 23.9154 4.71231 23.9154 5.48981V22.5075C23.9154 23.285 23.2851 23.9153 22.5076 23.9153H5.48981C4.71231 23.9153 4.08203 23.285 4.08203 22.5075V5.48981Z" fill="#0A66C2" />
                                <path d="M8.53694 10.2419C9.48717 10.2419 10.2575 9.47155 10.2575 8.52132C10.2575 7.57109 9.48717 6.80078 8.53694 6.80078C7.58672 6.80078 6.81641 7.57109 6.81641 8.52132C6.81641 9.47155 7.58672 10.2419 8.53694 10.2419Z" fill="white" />
                                <path d="M11.8332 11.5134H14.6847V12.8197C14.6847 12.8197 15.4585 11.2721 17.5638 11.2721C19.4419 11.2721 20.9977 12.1973 20.9977 15.0173V20.964H18.0428V15.7379C18.0428 14.0743 17.1546 13.8914 16.4779 13.8914C15.0733 13.8914 14.8329 15.1029 14.8329 15.9549V20.964H11.8332V11.5134Z" fill="white" />
                                <path d="M7.03711 11.5134H10.0368V20.964H7.03711V11.5134Z" fill="white" />
                            </svg>
                            <span><span className='d-m-none'>Log in</span> with Linkedn</span>
                        </button>
                        <button onClick={() => { handleSocialLogin('google-oauth') }} className='social-media-register-btn google'>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M23.52 14.2237C23.52 13.5205 23.4569 12.8444 23.3397 12.1953H14V16.0313H19.337C19.1071 17.2709 18.4084 18.3211 17.3581 19.0243V21.5125H20.563C22.4382 19.7861 23.52 17.2438 23.52 14.2237Z" fill="#4285F4" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.9986 23.9153C16.6761 23.9153 18.9209 23.0273 20.5617 21.5128L17.3568 19.0246C16.4688 19.6196 15.3329 19.9712 13.9986 19.9712C11.4158 19.9712 9.2296 18.2268 8.44979 15.8828H5.13672V18.4521C6.76846 21.6931 10.1221 23.9153 13.9986 23.9153Z" fill="#34A853" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.44988 15.8845C8.25155 15.2895 8.13886 14.654 8.13886 14.0004C8.13886 13.3468 8.25155 12.7112 8.44988 12.1162V9.54688H5.13681C4.46518 10.8856 4.08203 12.4002 4.08203 14.0004C4.08203 15.6006 4.46518 17.1151 5.13681 18.4539L8.44988 15.8845Z" fill="#FBBC05" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.9986 8.02617C15.4546 8.02617 16.7618 8.52651 17.7895 9.50916L20.6338 6.66488C18.9164 5.06468 16.6716 4.08203 13.9986 4.08203C10.1221 4.08203 6.76846 6.30427 5.13672 9.54522L8.44979 12.1145C9.2296 9.7706 11.4158 8.02617 13.9986 8.02617Z" fill="#EA4335" />
                            </svg>
                            <span><span className='d-m-none'>Log in</span> with Google</span>
                        </button>
                    </div>
                    <div className='already-member'>
                        <p>Don’t have any account? <Link to='/register'>Join us</Link></p>
                    </div>
                    <div className='terms-conditations'>
                        <p>By creating an account, you agree to our <Link to='/'>Terms of use</Link> and <Link to='/'> Privacy Policy</Link> </p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login