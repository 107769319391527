import React, { useState } from 'react'

const AnnotationButton = ({ id, position, normal, text }) => {
    const [show, setShow] = useState(false)

    return (
        <button
            className="hotspot"
            data-position={position}
            data-normal={normal}
            slot={`hotspot-${id}`}
            onClick={() => { setShow(!show) }}
        >
            {
                show && <div className='annotations-show'>
                    <p>{text}</p>
                </div>
            }
        </button>
    )
}

export default AnnotationButton