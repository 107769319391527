import { createSlice } from '@reduxjs/toolkit'
import { getUser } from '../actions/user'

const initialState = {
    loading: false,
    error: null,
    user: JSON.parse(localStorage.getItem('user')) || null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        socialAuth: (state, { payload }) => {
            state.loading = false
            localStorage.setItem('user', JSON.stringify(payload))
            state.user = payload
            state.error = null
        },
    },
    extraReducers: {
        [getUser.pending]: (state) => {
            state.loading = true
        },
        [getUser.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload || 'An error occurred while logging in.';
        },
        [getUser.fulfilled]: (state, { payload }) => {
            state.loading = false
            localStorage.setItem('user', JSON.stringify(payload))
            state.user = payload
            state.error = null
        },
    },

})

export const { clearError, socialAuth } = userSlice.actions;
export default userSlice.reducer