import { useState, useCallback, useEffect } from "react";

function useFullscreen(element) {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = useCallback(() => {
        if (document.fullscreenElement === null || document.webkitFullscreenElement === null) {
            element.requestFullscreen().catch((err) => {
                alert(
                    `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
                );
            });
        } else {
            document.exitFullscreen();
        }
        setIsFullscreen(!isFullscreen);
    }, [element, isFullscreen]);

    useEffect(() => {
        function escFullscreen(e) {
            if (!document.fullscreenElement) {
                document.getElementById('fullscreenBtn').classList.remove('active');
            } else {
                document.getElementById('fullscreenBtn').classList.add('active');
            }
        };

        document.addEventListener('fullscreenchange', escFullscreen);

        return () => {
            document.removeEventListener('fullscreenchange', escFullscreen);
        };
    }, []);

    return { isFullscreen, toggleFullscreen };
}

export default useFullscreen;
