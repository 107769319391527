import { createAsyncThunk } from '@reduxjs/toolkit'
import { axiosPrivate } from '../../api/api';

export const getOrderNotification = createAsyncThunk('request/fetchOrderNotification', async (_, { rejectWithValue }) => {
    try {
        const result = await axiosPrivate.get('/api/v1/orders/notifications/')
        return result.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})
