import React, { useState } from 'react'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import "./HomeGrafic.scss"

const data = [
    {
        name: "1",
        uv: 1000
    },
    {
        name: "2",
        uv: 2000
    },
    {
        name: "3",
        uv: 900
    },
    {
        name: "4",
        uv: 2400
    },
    {
        name: "5",
        uv: 2000
    },
    {
        name: "6",
        uv: 1000
    },
    {
        name: "7",
        uv: 1500
    },
    {
        name: "8",
        uv: 3000
    }
];

const HomeGrafic = () => {
    const tabs = ['Last 24 hour', 'Last week', 'Last month', 'Last year'];
    const [activeTab, setActiveTab] = useState(tabs[0]);

    return (
        <div className="highlights-grafic d-m-none">
            <div className='header'>
                <select>
                    <option value="0">Time on product</option>
                    <option value="1">Product views</option>
                    <option value="2">View in AR clicks</option>
                </select>
                <ul>
                    {tabs.map((tab) => (
                        <li
                            key={tab}
                            className={activeTab === tab ? 'active' : ''}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </li>
                    ))}
                </ul>
            </div>
            <div className='body' style={{ width: "100%", height: '272px' }}>
                <ResponsiveContainer>
                    <AreaChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5.77%" stopColor="#2b6cee" stopOpacity={0.2} />
                                <stop offset="95.67%" stopColor="#3577ea" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <Area type="monotone" dataKey="uv" stroke="#3578EA" fill="url(#colorUv)" />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default HomeGrafic