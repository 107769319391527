import React from 'react'

const style = {
    color: "#555E6D",
    fontSize: "16px",
    lineHeight: "24px",
}

const productSpan = {
    color: "#172640",
    fontWeight: 700,
}

const SearchResultText = ({ count, text }) => {
    return (
        <p style={style}>
            <span className='product-count' style={productSpan}>{count} </span>
            results for
            <span className='product-name' style={productSpan}> {text}</span>
        </p>
    )
}

export default SearchResultText