import React from 'react'
import './Orders.scss'
import SideMenu from '../../components/SideMenu/SideMenu'
import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { axiosPrivate } from '../../api/api'
import OrderProductSkeleton from '../../components/OrderProductSkeleton/OrderProductSkeleton'
import { Stack } from '@mui/system'
import { Pagination } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { changeOrderNotifications } from '../../redux/reducers/orderNotificationReducer'
import SearchResultText from '../../components/SearchResultText/SearchResultText'

const Orders = () => {
    const [activeTab, setActiveTab] = useState('all')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [pageCount, setPageCount] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [queryParams, setQueryParams] = useState({
        q: '',
        status: 'all',
        order: 'delivery_time',
        page: '1',
        page_size: '5'
    })
    const status = {
        all: 'All',
        in_progress: 'In Progress',
        review: 'Review & Feedback',
        revision: 'Under Revision',
        ready: 'Ready'
    }



    // * Handle status tab
    const dispatch = useDispatch()
    const { notification } = useSelector((state) => state.orderNotification)

    const setNotification = async (data) => {
        try {
            await axiosPrivate.post("/api/v1/orders/notifications/", data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleTabActive = (tabName) => {
        setActiveTab(tabName)
        changeQueryParams('status', tabName);

        if (notification[tabName]) {
            setNotification({
                [tabName]: false
            })
            dispatch(changeOrderNotifications(tabName))
        }
    }

    const handleSelectActive = (status) => {
        changeQueryParams('status', status);

        if (notification[status]) {
            setNotification({
                [status]: false
            })
            dispatch(changeOrderNotifications(status))
        }
    }

    //  * Fetch orders data
    useEffect(() => {
        const getOrders = async () => {
            setLoading(true);
            try {
                const result = await axiosPrivate.get(`/api/v1/orders/?q=${queryParams.q}&status=${queryParams.status}&order=${queryParams.order}&page=${queryParams.page}&page_size=${queryParams.page_size}`);
                setData(result.data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
        getOrders()
    }, [queryParams.order, queryParams.page, queryParams.page_size, queryParams.q, queryParams.status]);

    // * Get query params initial value from URL
    useEffect(() => {
        const keys = ['q', 'status', 'order', 'page', 'page_size'];
        const newState = { ...queryParams }

        keys.forEach(key => {
            const value = searchParams.get(key);
            if (value || value === '') {
                newState[key] = value
            }
        });

        setQueryParams(newState)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    // * Set initial value of active tab from url 
    useEffect(() => {
        setActiveTab(queryParams.status)
    }, [queryParams.status])

    // * Change query params function
    const changeQueryParams = (name, text) => {
        searchParams.set(name, text)
        setSearchParams(searchParams)

        if (name !== "page") {
            changeQueryParams("page", 1)
        }
    }

    // * Pagination
    useEffect(() => {
        setPageCount(Math.ceil(parseInt(data?.count) / parseInt(queryParams.page_size)))
    }, [data?.count, queryParams.page_size])

    // * Handle delivery time
    const handleDeliveryTime = (order) => {
        changeQueryParams('order', order === '-delivery_time' ? 'delivery_time' : '-delivery_time')
    }

    return (
        <>
            <SideMenu />
            <div className='orders-wrapper'>
                <div className='head'>
                    <h3>Order status</h3>
                    <p className='d-d-none sub-title-mobile'> A 3D model of your product is prepared.</p>
                    <div className='title'>
                        <p className='d-m-none'>On the progress page, a 3D model of your product is prepared.</p>
                        <select className='d-d-none mobile-custom-select' onChange={(e) => { handleSelectActive(e.target.value) }} value={searchParams.get("status")}>
                            <option value="all">All</option>
                            <option value="in_progress">In progress</option>
                            <option value="review">Review & Feedback</option>
                            <option value="revision">Under revision</option>
                            <option value="ready">Ready</option>
                        </select>
                        <div className='search-wrapper'>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.51562 16.4062C13.3212 16.4062 16.4062 13.3212 16.4062 9.51562C16.4062 5.71004 13.3212 2.625 9.51562 2.625C5.71004 2.625 2.625 5.71004 2.625 9.51562C2.625 13.3212 5.71004 16.4062 9.51562 16.4062Z" stroke="#A1A8B4" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" />
                                <path d="M14.3882 14.3867L18.3749 18.3734" stroke="#A1A8B4" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" />
                            </svg>
                            <input
                                type="text"
                                placeholder={`Search for ${status[activeTab]}...`}
                                onChange={(e) => { changeQueryParams('q', e.target.value) }}
                                value={queryParams.q}
                            />
                        </div>
                    </div>
                </div>

                <div className='tab-wrapper'>
                    <ul>
                        <li>
                            <button onClick={() => { handleTabActive('all') }} className={`${activeTab === 'all' ? 'active' : null}`}>
                                <span className='text'>All</span>
                                <span className='count'>{data?.all}</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => { handleTabActive('in_progress') }} className={`${activeTab === 'in_progress' ? 'active' : null}`}>
                                <span className='text'>In progress</span>
                                <span className='count'>{data?.in_progress}</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => { handleTabActive('review') }} className={`${activeTab === 'review' ? 'active' : null}`}>
                                <span className='text'>Review & Feedback</span>
                                <span className='count'>
                                    {data?.review}
                                    {notification.review && <div className="notification"></div>}
                                </span>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => { handleTabActive('revision') }} className={`${activeTab === 'revision' ? 'active' : null}`}>
                                <span className='text'>Under revision</span>
                                <span className='count'>{data?.revision}</span>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => { handleTabActive('ready') }} className={`${activeTab === 'ready' ? 'active' : null}`}>
                                <span className='text'>Ready</span>
                                <span className='count'>{data?.ready}</span>
                            </button>
                        </li>
                    </ul>

                    {queryParams.q && <SearchResultText count={data.count} text={queryParams.q} />}
                </div>

                <div className='table-wrapper'>
                    <div className='table-head'>
                        <ul>
                            <li>Product details</li>
                            <li className='d-m-none'>SKU</li>
                            <li className='d-m-none'>
                                Delivery time
                                <button onClick={() => { handleDeliveryTime(queryParams.order) }}>
                                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.75 7.5L6 3.75L2.25 7.5" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.75 14.5L6 18.25L2.25 14.5" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </li>
                            <li>Status</li>
                        </ul>
                    </div>
                    <div className='table-body'>
                        {
                            loading
                                ? [1, 2, 3, 4, 5].map((skeleton, index) => <div className='order-product-skeleton' key={index}><OrderProductSkeleton /></div>)
                                : data?.results?.map(order => {
                                    return (
                                        <Link to={`${['review', 'revision'].includes(order.status) ? `/orders/${order.id}` : ['ready'].includes(order.status) ? `/my-products/${order.model}` : ''}`} className='list-item' key={order.id}>
                                            <div className='title'>
                                                <div className='image'>
                                                    <img src={order.image} alt={order.name} />
                                                </div>
                                                <h3 className='name'>{order.name}</h3>
                                            </div>
                                            <p className='sku d-m-none'>{order.sku}</p>
                                            <p className='delivery time d-m-none'>{order.delivery_time.slice(0, 13)}</p>
                                            <p className='order-status'>
                                                <span className={order.status}></span>
                                                {order.status_name}
                                            </p>
                                        </Link>
                                    )
                                })
                        }
                    </div>
                    <div className='table-pagination'>
                        <div className='rows-per-page'>
                            <p>Rows per page</p>
                            <select onChange={(e) => { changeQueryParams('page_size', e.target.value) }}>
                                {
                                    [5, 10, 25, 50].map(option =>
                                        <option
                                            key={option}
                                            value={option || 5}
                                            {...(parseInt(queryParams.page_size) === option && { "selected": true })}
                                        >
                                            {option}
                                        </option>)
                                }
                            </select>
                        </div>
                        <div className='pagination'>
                            <Stack spacing={2}>
                                <Pagination
                                    count={pageCount || 1}
                                    page={parseInt(queryParams.page)}
                                    onChange={(e, value) => { changeQueryParams('page', value) }}
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Orders