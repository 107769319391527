import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { autoLoad, autoRotate } from "../../redux/reducers/modelViewerReducer";

export default function ControlledSwitches({ name }) {
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch()
    const { data } = useSelector((state) => state.modelViewer)

    const handleChange = (event) => {
        setChecked(event.target.checked);

        if (name === "autoRotate") {
            dispatch(autoRotate({ "is_autorotate": event.target.checked, id: data.id }))
        } else if (name === "autoLoad") {
            dispatch(autoLoad({ "is_autoload": event.target.checked, id: data.id }))
        }
    };

    useEffect(() => {
        if (name === "autoRotate") {
            setChecked(data.is_autorotate || false)
        }
    }, [data.is_autorotate, name])

    useEffect(() => {
        if (name === "autoLoad") {
            setChecked(data.is_autoload || false)
        }
    }, [data.is_autoload, name])

    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}