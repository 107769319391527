import React, { useEffect, useState } from 'react'
import './SideMenu.scss'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRequestNotification } from '../../redux/actions/requestNotification'
import { getOrderNotification } from '../../redux/actions/orderNotification'

const SideMenu = () => {
    const dispatch = useDispatch()
    const { notification } = useSelector((state) => state.requestNotification)
    const { orderNotification } = useSelector((state) => state)
    const [toggle, setToggle] = useState(JSON.parse(localStorage.getItem('toggleMenu')) || false)

    useEffect(() => {
        dispatch(getRequestNotification())
        dispatch(getOrderNotification())
    }, [dispatch])

    useEffect(() => {
        localStorage.setItem('toggleMenu', toggle)
    }, [toggle])

    const notificationVisible = Object.keys(notification).some(key => notification[key]);
    const orderNotificationVisible = Object.keys(orderNotification.notification).some(key => orderNotification.notification[key]);

    return (
        <>
            <div className={`sidemenu ${toggle ? 'toggle-close' : null}`}>
                <button className='side-menu-toggle' onClick={() => { setToggle(!toggle) }}>
                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.125 1.625L1.375 5L5.125 8.375" stroke="#F6FAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                <div className='sidemenu-wrapper'>
                    <div className='menu-list-wrapper'>
                        <ul className='list-items'>
                            <NavLink to='/' className="item">
                                <div className='icon'>
                                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.4688 17.5631V13.6256C12.4688 13.4515 12.3996 13.2846 12.2765 13.1615C12.1535 13.0385 11.9865 12.9693 11.8125 12.9693H9.1875C9.01345 12.9693 8.84653 13.0385 8.72346 13.1615C8.60039 13.2846 8.53125 13.4515 8.53125 13.6256V17.5631C8.53125 17.7371 8.46211 17.904 8.33904 18.0271C8.21597 18.1502 8.04905 18.2193 7.875 18.2193H3.9375C3.76345 18.2193 3.59653 18.1502 3.47346 18.0271C3.35039 17.904 3.28125 17.7371 3.28125 17.5631V9.97518C3.28272 9.88436 3.30235 9.79475 3.33898 9.71163C3.3756 9.62851 3.42849 9.55356 3.49453 9.4912L10.057 3.52753C10.178 3.41685 10.336 3.35547 10.5 3.35547C10.664 3.35547 10.822 3.41685 10.943 3.52753L17.5055 9.4912C17.5715 9.55356 17.6244 9.62851 17.661 9.71163C17.6977 9.79475 17.7173 9.88436 17.7188 9.97518V17.5631C17.7188 17.7371 17.6496 17.904 17.5265 18.0271C17.4035 18.1502 17.2365 18.2193 17.0625 18.2193H13.125C12.951 18.2193 12.784 18.1502 12.661 18.0271C12.5379 17.904 12.4688 17.7371 12.4688 17.5631Z" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <p>Dashboard</p>
                            </NavLink>
                            <NavLink to='/requests' className="item">
                                <div className='icon'>
                                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.57812 18.2188C6.84665 18.2188 7.875 17.1904 7.875 15.9219C7.875 14.6533 6.84665 13.625 5.57812 13.625C4.3096 13.625 3.28125 14.6533 3.28125 15.9219C3.28125 17.1904 4.3096 18.2188 5.57812 18.2188Z" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.57812 8.375C6.84665 8.375 7.875 7.34665 7.875 6.07812C7.875 4.8096 6.84665 3.78125 5.57812 3.78125C4.3096 3.78125 3.28125 4.8096 3.28125 6.07812C3.28125 7.34665 4.3096 8.375 5.57812 8.375Z" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M5.57812 8.375V13.625" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.4219 18.2188C16.6904 18.2188 17.7188 17.1904 17.7188 15.9219C17.7188 14.6533 16.6904 13.625 15.4219 13.625C14.1533 13.625 13.125 14.6533 13.125 15.9219C13.125 17.1904 14.1533 18.2188 15.4219 18.2188Z" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M15.4219 13.625V10.3355C15.4205 9.28946 15.0046 8.28656 14.2652 7.54648L11.1562 4.4375" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M11.1562 7.71875V4.4375H14.4375" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    {notificationVisible && <div className='notification'></div>}
                                </div>
                                <p>All requests</p>
                            </NavLink>
                            <NavLink to='/orders' className="item">
                                <div className='icon'>
                                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.60938 12.2551V4.76562C3.60938 4.59158 3.67852 4.42466 3.80159 4.30159C3.92466 4.17852 4.09158 4.10937 4.26562 4.10938H16.7344C16.9084 4.10938 17.0753 4.17852 17.1984 4.30159C17.3215 4.42466 17.3906 4.59158 17.3906 4.76562V17.2344C17.3906 17.4084 17.3215 17.5753 17.1984 17.6984C17.0753 17.8215 16.9084 17.8906 16.7344 17.8906H11.1234" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.5 12.9688L5.25 18.2188L2.625 15.5938" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {orderNotificationVisible && <div className='notification'></div>}
                                </div>
                                <p>Orders</p>
                            </NavLink>
                            <NavLink to='/my-products' className="item">
                                <div className='icon'>
                                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.0625 3.78125H3.9375C3.57506 3.78125 3.28125 4.07506 3.28125 4.4375V17.5625C3.28125 17.9249 3.57506 18.2188 3.9375 18.2188H17.0625C17.4249 18.2188 17.7188 17.9249 17.7188 17.5625V4.4375C17.7188 4.07506 17.4249 3.78125 17.0625 3.78125Z" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M7.71914 11.5L10.5 14.2809L13.2809 11.5" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.5 7.71875V14.2812" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <p>My Products</p>
                            </NavLink>
                            <NavLink to='/library' className="item disabled-link">
                                <div className='icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                                        <path d="M10.4994 3.125L17.3192 7.0625V13.9269C17.3192 14.2341 17.2383 14.5358 17.0847 14.8018C16.9311 15.0679 16.7102 15.2888 16.4442 15.4424L11.3744 18.3701C11.1084 18.5237 10.8066 18.6046 10.4994 18.6046C10.1922 18.6046 9.89047 18.5237 9.62444 18.3701L4.55469 15.4424C4.28866 15.2888 4.06775 15.0679 3.91415 14.8018C3.76056 14.5358 3.6797 14.2341 3.67969 13.9269V7.0625L10.4994 3.125Z" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M10.4997 6.625V11M10.4997 11L6.71094 13.1875M10.4997 11L14.2884 13.1875" stroke="#A1A8B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <p>3d Library</p>
                            </NavLink>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SideMenu