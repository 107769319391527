import React, { useEffect, useState } from 'react'
import './NewRequest.scss'
import SideMenu from '../../components/SideMenu/SideMenu'
import Request from '../../components/Request/Request'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestData } from '../../redux/actions/request';
import RequestSkeleton from '../../components/RequestSkeleton/RequestSkeleton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { axiosPrivate } from '../../api/api'
import { emptyProduct, getSingleProduct } from '../../redux/reducers/requestReducer';
import { CircularProgress } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';

const NewRequest = () => {
    const { data, loading } = useSelector((state) => state.request)
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [productsID, setProductsID] = useState(searchParams.get('products')?.split(',').map(Number) || [])
    const [overlay, setOverlay] = useState(false)
    const notify = (message) => toast(message);
    const navigate = useNavigate()
    const { register, formState: { errors }, handleSubmit, setError, clearErrors } = useForm();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [ai, setAi] = useState(false)
    const [imageLength, setImageLength] = useState(0)

    const onSubmit = (data) => {
        setIsSubmitted(true)

        if (!(imageLength < (ai ? 10 : 3))) {
            handleSubmitRequest()
        }
    };

    const url = searchParams.get('products')

    useEffect(() => {
        if (!url) {
            dispatch(getRequestData()).then((item) => setProductsID([...productsID, item.payload.id]))
            dispatch(emptyProduct())
        } else {
            dispatch(emptyProduct())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAddNew = () => {
        dispatch(getRequestData()).then((item) => setProductsID([...productsID, item.payload.id]))
    }

    const getProductDetail = async (id) => {
        try {
            const response = await axiosPrivate.get(`/api/v1/requests/products/${id}/`)
            return response.data
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        if (productsID.length > 0) {
            searchParams.set('products', productsID)
            setSearchParams(searchParams)
        }
    }, [productsID, searchParams, setSearchParams])

    useEffect(() => {
        if (productsID.length > 0) {
            productsID.forEach(id => getProductDetail(id).then(response => dispatch(getSingleProduct(response))))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmitRequest = async () => {
        setOverlay(true)
        try {
            await axiosPrivate.post('/api/v1/requests/', productsID)
            navigate('/requests?status=under_assessment&page=1', { state: { message: 'We have received your request' } })
        } catch (error) {
            console.log(error);
            notify(error.message)
        }
        setOverlay(false)
    }

    const handleSubmitClick = () => {
        setIsSubmitted(true)
    };

    return (
        <>
            {overlay && <div className='request-overlay'>
                <CircularProgress width="50" />
            </div>}
            <ToastContainer autoClose={2000} />
            <SideMenu />
            <form
                onSubmit={handleSubmit(onSubmit, handleSubmitClick)}
                className='new-request-wrapper'
            >
                <div className='title'>
                    <h3>Upload your product references</h3>
                    <p>To create an order, product references are required</p>
                </div>

                <div className="new-request-list">
                    {loading && [1, 2, 3,].map(item => <RequestSkeleton key={item} />)}
                    {data.length > 0 && data.map((product) =>
                        <Request
                            key={product.id}
                            id={product.id}
                            product={product}
                            productsID={productsID}
                            setProductsID={setProductsID}
                            register={register}
                            errors={errors}
                            setError={setError}
                            clearErrors={clearErrors}
                            isSubmitted={isSubmitted}
                            ai={ai}
                            setAi={setAi}
                            setImageLength={setImageLength}
                        />)}
                </div>

                <div className="footer">
                    <button onClick={() => { handleAddNew() }} className='add-new-button' type='button'>
                        <AddRoundedIcon />
                        <span>Add new product</span>
                    </button>
                    <button
                        className='send-request-button'
                    >Send request</button>
                </div>
            </form>
        </>
    )
}

export default NewRequest