import React, { useEffect, useState } from 'react';
import { axiosPrivate } from '../../api/api';
import { Controller, useForm } from 'react-hook-form';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const ConfirmResetPassword = () => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const [searchParams] = useSearchParams()
    const [token, setToken] = useState(null)
    const [serverErrors, setServerErrors] = useState(null)
    const [passwordShow, setPasswordShow] = useState(false)

    const notify = (message) => toast(message);
    const navigate = useNavigate()

    useEffect(() => {
        setToken(searchParams.get('token'))
    }, [searchParams, searchParams.token])

    const resetPassword = async (data) => {
        try {
            const result = await axiosPrivate.post('/api/v1/accounts/reset-password/confirm/', {
                "password": data.password,
                "token": token
            })

            if (result.data.status === "OK") {
                notify('Your password has been updated')
                setTimeout(() => {
                    navigate('/login')
                }, [3000]);
            }
        } catch (error) {
            setServerErrors(error.response.data.password)
        }
    }

    return (
        <>
            <ToastContainer />
            <div className='reset-password-wrapper'>
                <div className='reset-password'>
                    <h4>Confirm your new password?</h4>
                    <form onSubmit={handleSubmit((data) => { resetPassword(data) })}>
                        <div className="input-group">
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'Password is required' }}
                                render={({ field }) => (
                                    <div className='input-showpassword'>
                                        <input type={passwordShow === false ? "password" : "text"} placeholder="Password" {...field} />
                                        <button type='button' onClick={() => { setPasswordShow(!passwordShow) }}>
                                            {passwordShow
                                                ? <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path d="M10.5 4.59375C3.9375 4.59375 1.3125 10.5 1.3125 10.5C1.3125 10.5 3.9375 16.4062 10.5 16.4062C17.0625 16.4062 19.6875 10.5 19.6875 10.5C19.6875 10.5 17.0625 4.59375 10.5 4.59375Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M10.5 13.7812C12.3122 13.7812 13.7812 12.3122 13.7812 10.5C13.7812 8.68782 12.3122 7.21875 10.5 7.21875C8.68782 7.21875 7.21875 8.68782 7.21875 10.5C7.21875 12.3122 8.68782 13.7812 10.5 13.7812Z" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <path d="M16.4961 10.4424L18.3746 13.6826" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.6494 12.2471L13.2318 15.5529" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M8.34219 12.2393L7.75977 15.5533" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M4.4957 10.4424L2.61719 13.699" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M2.625 8.60547C4.00312 10.3117 6.52969 12.4691 10.5 12.4691C14.4703 12.4691 16.9969 10.3117 18.375 8.60547" stroke="#7E899B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>}
                                        </button>
                                    </div>
                                )}
                            />
                            <div className="error-text login-error-forgot-password">
                                <div>
                                    {errors.password && errors.password.message}
                                    {serverErrors?.map((item, index) => {
                                        return (
                                            <p className='error-text' key={index}>{item}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='btn-groups'>
                            <button className='btn_primary'>Reset</button>
                        </div>
                        <Link to="/login" className='back-button'>Back</Link>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ConfirmResetPassword