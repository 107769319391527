import React, { useState } from 'react';
import { axiosPrivate } from '../../api/api';
import './ForgotPassword.scss';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
const notify = (message) => toast(message);

const ForgotPassword = () => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const [serverErrors, setServerErrors] = useState(null)

    const resetPassword = async (data) => {
        try {
            const result = await axiosPrivate.post('/api/v1/accounts/reset-password/', data);

            if (result.data.status === "OK") {
                notify('Check your email')
            }
        } catch (error) {
            setServerErrors(error.response.data)
        }
    }

    return (
        <>
            <ToastContainer />
            <div className='reset-password-wrapper'>
                <div className='reset-password'>
                    <h4>Forgot your password?</h4>
                    <p className='description'>Your password will be reset by email.</p>
                    <form onSubmit={handleSubmit((data) => { resetPassword(data) })}>
                        <div className="input-group">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address',
                                    },
                                }}
                                render={({ field }) => (
                                    <input type="text" placeholder="Email" {...field} />
                                )}
                            />
                            <div className="error-text">
                                {errors.email && errors.email.message}
                                {serverErrors?.email}
                            </div>
                        </div>
                        <div className='btn-groups'>
                            <button className='reset-password-btn'>Send Request</button>
                        </div>
                        <Link to="/login" className='back-button'>Back</Link>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword