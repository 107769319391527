import { createAsyncThunk, current } from '@reduxjs/toolkit'
import { axiosPrivate } from '../../api/api';

export const getRequestData = createAsyncThunk('request/fetchRequest', async (_, { rejectWithValue }) => {
    try {
        const result = await axiosPrivate.post('/api/v1/requests/products/')
        return result.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})

export const updateSingleProduct = createAsyncThunk('request/updateRequest', async ({ id, field, data }, { rejectWithValue }) => {
    try {
        const response = await axiosPrivate.patch(`/api/v1/requests/products/${id}/`, { [field]: data });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
}, { meta: { arg: {} } })

const deleteSingleProduct = (state, { payload }) => {
    const products = [...current(state).data]
    const updatedProducts = products.filter((product) => product.id !== payload);

    const deleteFromServer = async (id) => {
        try {
            await axiosPrivate.delete(`/api/v1/requests/products/`, { data: [id] });
        } catch (error) {
            console.log(error);
        }
    };

    deleteFromServer(payload)
    state.data = updatedProducts
}

const getSingleProductDetail = (state, { payload }) => {
    state.data.push(payload)
}

export { deleteSingleProduct, getSingleProductDetail }