import React from 'react';
import { Routes, Route } from 'react-router-dom'
import ReactGA from "react-ga4"
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import RegisterSuccess from './pages/RegisterSuccess/RegisterSuccess';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Success from './pages/Success/Success';
import ConfirmResetPassword from './pages/ConfirmResetPassword/ConfirmResetPassword';
import RequestList from './pages/RequestList/RequestList'
import Checkout from './pages/Checkout/Checkout';
import Orders from './pages/Orders/Orders';
import Response from './pages/Response/Response';
import NotFound from './pages/404/404';
import OrderDetails from './pages/OrderDetails/OrderDetails';
import MyProducts from './pages/MyProducts/MyProducts';
import MyProductsDetails from './pages/MyProductsDetails/MyProductsDetails';
import PreviewModel from './pages/PreviewModel/PreviewModel';
import NewRequest from './pages/NewRequest/NewRequest';
import Register from './pages/Register/Register';
import ProtectedRoutes from './routes/ProtectedRoutes';
import AuthRoutes from './routes/AuthRoutes';
import Verify from './pages/Verify/Verify';

function App() {

  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GA_MEASUREMENT_ID,
    }
  ]);

  return (
    <>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route index element={<Home />} />
          <Route path="/requests" element={<RequestList />} />
          <Route path="/new-request" element={<NewRequest />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/orders/:id" element={<OrderDetails />} />
          <Route path="/my-products" element={<MyProducts />} />
          <Route path="/my-products/:id" element={<MyProductsDetails />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path="/payment/success" element={<Response />} />
        </Route>
        <Route path="/" element={<AuthRoutes />}>
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/reset-password' element={<ForgotPassword />} />
          <Route path='/success' element={<Success />} />
          <Route path='/register-success' element={<RegisterSuccess />} />
          <Route path='/confirm-password' element={<ConfirmResetPassword />} />
          <Route path='/auth/verify' element={<Verify />} />
        </Route>

        <Route path="/model/viewer/:id" element={<PreviewModel />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
}



export default App;
