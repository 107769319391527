import React, { useEffect, useRef, useState } from 'react'
import './OrderDetails.scss'
import { useNavigate, useParams } from 'react-router-dom'
import ModelConfigurator from '../../components/ModelConfigurator/ModelConfigurator'
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import { axiosPrivate } from '../../api/api';
import useTabs from '../../hooks/useTabs';
import ModelDetails from '../../components/ModelDetails/ModelDetails';
import ModelActions from '../../components/ModelActions/ModelActions';
import ModelDetailsBreadcrumb from '../../components/ModelDetailsBreadcrumb/ModelDetailsBreadcrumb';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Modal } from '@mui/material';

const feedbackTabs = [
    {
        name: "Confirm",
        slug: "confirm"
    },
    {
        name: "Add Feedback",
        slug: "add_feedback"
    },
]

const OrderDetails = () => {
    const order_id = useParams().id
    const [orderDetails, setOrderDetails] = useState(null)
    const { activeTab, onTabChange } = useTabs(0, feedbackTabs)
    const [feedbackFormStatus, setFeedbackFormStatus] = useState(false)
    const [annotainsElements, setAnnotainsElements] = useState([]);
    const [removeAnnotion, setRemoveAnnotion] = useState(false)
    const ModelDetailsBreadcrumbs = [
        {
            name: 'Home',
            url: '/'
        },
        {
            name: 'Order',
            url: '/orders?q=&status=all&order=delivery_time&page=1&page_size=10'
        },
        {
            name: `${orderDetails?.status_name}`,
            url: `${`/orders?q=&status=${orderDetails?.status}&order=delivery_time&page=1&page_size=10`}`
        },
        {
            name: `${orderDetails?.sku}`,
            url: ''
        },
    ]
    const [sendFeedbackStatus, setSendFeedbackStatus] = useState(false)
    const [annotainsData, setAnnotainsData] = useState([])
    const [annotainsText, setAnnotainsText] = useState([])
    const [searchText, setSearchText] = useState('')
    const [user, setUser] = useState(null)

    const navigate = useNavigate()

    // * Fetch user details
    useEffect(() => {
        try {
            const getUser = async () => {
                const result = await axiosPrivate.get(`/api/v1/customers/profile/`)
                setUser(result.data);
            }

            getUser()
        } catch (error) {
            console.log(error);
        }

    }, [])


    // * Fetch order details
    useEffect(() => {
        try {
            const getData = async () => {
                const result = await axiosPrivate.get(`/api/v1/orders/${order_id}/`)
                setOrderDetails(result.data);
            }

            getData()
        } catch (error) {
            console.log(error);
        }

    }, [order_id])

    useEffect(() => {
        setAnnotainsData(orderDetails?.feedbacks)
        setAnnotainsText(orderDetails?.feedbacks)
    }, [orderDetails?.feedbacks])

    // * Feedback form logic
    const feedbackForm = useRef(null);

    useEffect(() => {
        if (removeAnnotion) {
            annotainsElements.pop()
            setAnnotainsElements(annotainsElements)
            setRemoveAnnotion(false)
        }
    }, [annotainsElements, removeAnnotion])

    const handleClickOutside = (event) => {
        if (feedbackForm.current && !feedbackForm.current.contains(event.target)) {
            setFeedbackFormStatus(false)
            document.removeEventListener("click", handleClickOutside, true)
            setRemoveAnnotion(true)
        }
    }

    document.addEventListener("click", handleClickOutside, true)

    // * Handle Feedback Form
    const handleForm = (e) => {
        const data = annotainsElements[annotainsElements.length - 1].element.props;
        const result = {
            "position": data['data-position'],
            "normal": data['data-normal']
        }

        e.preventDefault()
        setAnnotainsData((prev) => [...prev, { id: annotainsElements[annotainsElements.length - 1].id, text: searchText, created_at: currentDate(), reviewer_fullname: user.name + ' ' + user.surname }])
        setAnnotainsText((prev) => [...prev, { id: annotainsElements[annotainsElements.length - 1].id, data_position: result, text: searchText, created_at: currentDate(), reviewer_fullname: user.name + ' ' + user.surname }])
        createFeedbackText({
            text: searchText
        })
        setSearchText('')
        setFeedbackFormStatus(false)
    }

    const currentDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd
    };


    const createFeedbackText = async (message) => {
        try {
            const result = await axiosPrivate.patch(`/api/v1/orders/feedback/${annotainsElements[annotainsElements.length - 1].id}/`, message)
            setSendFeedbackStatus(result.data.can_send_feedback)
        } catch (error) {
            console.log(error);
        }
    }

    // * Delete Feedback
    const deleteFeedback = (id) => {
        const newData = annotainsData.filter((item) => {
            if (item.id !== id) {
                return item
            } else return false
        })

        const newAnno = annotainsText.filter((item) => {
            if (item.id !== id) {
                return item
            } else return false
        })

        setAnnotainsData(newData);
        setAnnotainsText(newAnno)

        const deleteBack = async (id) => {
            const result = await axiosPrivate.delete(`/api/v1/orders/feedback/${id}/`)
            setSendFeedbackStatus(result.data.can_send_feedback)
        }

        deleteBack(id)
    }

    // * Send Feedback
    const sendFeedback = async (id) => {
        await axiosPrivate.post(`/api/v1/orders/${id}/send-feedback/`)
        navigate('/orders?q=&status=revision&order=delivery_time&page=1&page_size=10')
    }

    // * Confirm Feedback
    const confirmFeedback = async (id) => {
        await axiosPrivate.post(`/api/v1/orders/${id}/confirm/`)
        navigate('/orders?q=&status=ready&order=delivery_time&page=1&page_size=10')
    }

    // * Modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "90%",
        maxWidth: 400,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 4,
    };

    function BasicModal({ id, status }) {
        const [open, setOpen] = React.useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        return (
            <div>
                {
                    status === 'delete'
                        ? <DeleteIcon
                            className='icon'
                            onClick={handleOpen}
                        />
                        : status === "confirm"
                            ? <button onClick={handleOpen} className={`${orderDetails?.can_confirm ? '' : 'disabled-btn'} btn_primary`}>Confirm</button>
                            : <button onClick={handleOpen} className={`${sendFeedbackStatus ? '' : 'disabled-btn'} btn_primary`}>Send Feedback</button>
                }
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='feedback-modal-wrapper'>
                            {
                                status === 'delete'
                                    ? <>
                                        <h3>Are you sure ?</h3>
                                        <p className='text'>Are you sure you want to delete this comment? </p>
                                        <div className="btn-groups">
                                            <button onClick={handleClose}>Cancel</button>
                                            <button onClick={() => { deleteFeedback(id) }}>Delete</button>
                                        </div>
                                    </>
                                    : status === 'confirm'
                                        ? <>
                                            <h3>Are you sure want to confirm?</h3>
                                            <p className='text'>Are you sure you want to confirm? </p>
                                            <div className="btn-groups">
                                                <button onClick={handleClose}>Cancel</button>
                                                <button onClick={() => { confirmFeedback(id) }}>Confirm</button>
                                            </div>
                                        </>
                                        : <>
                                            <h3>Are you sure want to send?</h3>
                                            <p className='text'>Please once you have completed all the feedback, click the send button.</p>
                                            <div className="btn-groups">
                                                <button onClick={handleClose}>Cancel</button>
                                                <button onClick={() => { sendFeedback(id) }}>Send</button>
                                            </div>
                                        </>
                            }
                        </div>
                    </Box>
                </Modal>
            </div >
        );
    }

    useEffect(() => {
        setSendFeedbackStatus(orderDetails?.can_send_feedback)
    }, [orderDetails?.can_send_feedback])

    return (
        <div className='order-wrapper'>
            <ModelDetailsBreadcrumb children={ModelDetailsBreadcrumbs} />
            <div className='order-details-wrapper'>
                <div className='model-side'>
                    <div className='model-block'>
                        <ModelConfigurator
                            model={orderDetails?.model.file}
                            usdz={orderDetails?.model.usdz}
                            bgColor={"#e8e9ea"}
                            annotainsElements={annotainsElements}
                            setAnnotainsElements={setAnnotainsElements}
                            setFeedbackFormStatus={setFeedbackFormStatus}
                            onTabChange={onTabChange}
                            orderId={order_id}
                            annotainsText={annotainsText}
                            status={orderDetails?.status}
                            variants={orderDetails?.model.variants}
                        />
                    </div>
                    <ModelActions name={orderDetails?.name} active={false} />
                    <div className='d-m-none'><ModelDetails /></div>
                </div>
                <div className='confirm-feedback-side'>
                    <div className='confirm-feedback-alt'>
                        <div className='head'>
                            {feedbackTabs.map((child, index) => (
                                <button
                                    key={index}
                                    onClick={() => onTabChange(index)}
                                    className={`${activeTab === index ? 'active' : ''} btn_custom_tab`}
                                >
                                    <div className='circle'></div>
                                    <span>{child.name}</span>
                                </button>
                            ))}
                        </div>
                        <div className='body'>
                            {
                                activeTab === 0
                                    ? <div className='confirm-message'>
                                        <div className='title'>
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.5 18.375C14.8492 18.375 18.375 14.8492 18.375 10.5C18.375 6.15076 14.8492 2.625 10.5 2.625C6.15076 2.625 2.625 6.15076 2.625 10.5C2.625 14.8492 6.15076 18.375 10.5 18.375Z" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.84375 9.84375H10.5V14.4375H11.1562" stroke="#4D9A00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.3359 7.875C10.8796 7.875 11.3203 7.43428 11.3203 6.89062C11.3203 6.34697 10.8796 5.90625 10.3359 5.90625C9.79228 5.90625 9.35156 6.34697 9.35156 6.89062C9.35156 7.43428 9.79228 7.875 10.3359 7.875Z" fill="#4D9A00" />
                                            </svg>
                                            <p>Our team has completed your order</p>
                                        </div>
                                        <p className='text'>Click the confirm order button if you are satisfied with your order, or provide feedback if any changes need to be made.</p>
                                    </div>
                                    : <div className='feedback-wrapper'>
                                        <div className='feedback-content'>
                                            <div className="feedback-message-wrapper">
                                                {
                                                    annotainsData?.map((item, index) => {
                                                        return (
                                                            <div key={index} className='feedback-message'>
                                                                <div className="title">
                                                                    <div className="left">
                                                                        <div></div>
                                                                        <p>{item.reviewer_fullname}</p>
                                                                    </div>
                                                                    <div className="right">
                                                                        <p className='date'>{item?.created_at?.slice(0, 10)}</p>
                                                                        <BasicModal id={item.id} status="delete" />
                                                                    </div>
                                                                </div>
                                                                <div className="content">
                                                                    <p>{item.text}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                feedbackFormStatus
                                                    ? <form
                                                        className='input-wrapper'
                                                        ref={feedbackForm}
                                                        onSubmit={(e) => { handleForm(e) }}
                                                    >
                                                        <input
                                                            placeholder='Please type your text'
                                                            onChange={(e) => { setSearchText(e.target.value) }}
                                                            value={searchText}
                                                            autoFocus
                                                        />
                                                        <button className='feedback-send'>
                                                            <ArrowUpwardRoundedIcon />
                                                        </button>
                                                    </form>
                                                    : annotainsData?.length > 0
                                                        ? ''
                                                        : <div className='empty-feedback-info'>
                                                            <div className='title'>
                                                                <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M10.5 18.875C14.8492 18.875 18.375 15.3492 18.375 11C18.375 6.65076 14.8492 3.125 10.5 3.125C6.15076 3.125 2.625 6.65076 2.625 11C2.625 15.3492 6.15076 18.875 10.5 18.875Z" stroke="#B86503" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M9.84375 10.3438H10.5V14.9375H11.1562" stroke="#B86503" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M10.3359 8.375C10.8796 8.375 11.3203 7.93428 11.3203 7.39062C11.3203 6.84697 10.8796 6.40625 10.3359 6.40625C9.79228 6.40625 9.35156 6.84697 9.35156 7.39062C9.35156 7.93428 9.79228 8.375 10.3359 8.375Z" fill="#B86503" />
                                                                </svg>
                                                                <p>Let us know how we can improve your order</p>
                                                            </div>
                                                            <p className='text'>Click the confirm order button if you are satisfied with your order, or provide feedback if any changes need to be made.</p>
                                                        </div>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    {
                        activeTab === 0
                            ? <BasicModal id={order_id} status="confirm" />
                            : <BasicModal id={order_id} status="send_feedback" />

                    }
                </div>
                <div className='d-d-none'><ModelDetails /></div>
            </div>
        </div >
    )
}

export default OrderDetails 