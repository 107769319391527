import { createSlice } from '@reduxjs/toolkit'
import { deleteSingleProduct, getRequestData, getSingleProductDetail, updateSingleProduct } from '../actions/request'

const initialState = {
    loading: false,
    error: null,
    imageLoading: [],
    data: [],
}

const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        deleteProduct: deleteSingleProduct,
        getSingleProduct: getSingleProductDetail,
        emptyProduct: (state) => {
            state.data = []
        }
    },
    extraReducers: {
        [getRequestData.pending]: (state) => {
            state.loading = true
        },
        [getRequestData.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getRequestData.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.data = [...state.data, payload]
        },
        [updateSingleProduct.pending]: (state, { meta }) => {
            const { id, field } = meta.arg;
            if (field === 'images') {
                state.imageLoading = {
                    ...state.imageLoading,
                    [id]: state.imageLoading[id] || true
                };
            }
        },
        [updateSingleProduct.fulfilled]: (state, { meta }) => {
            const { id, field } = meta.arg;
            if (field === 'images' && state.imageLoading[id]) {
                state.imageLoading[id] = false;
            }
        }
    },
})

export const {
    deleteProduct,
    getSingleProduct,
    emptyProduct
} = requestSlice.actions
export default requestSlice.reducer