import React from 'react'
import './Response.scss'
import success_image from '../../images/success.png'
import { Link } from 'react-router-dom'

const Response = () => {
    return (
        <div className='response-wrapper'>
            <div className='image'>
                <img src={success_image} alt="response" />
            </div>
            <h3>Payment Succesfull!</h3>
            <p>Thank you for choosing Imajinn. Your order will be generated within five bussines days.</p>
            <div className='response-button-groups'>
                <Link to="/orders?q=&status=all&order=-created_at&page=1&page_size=10" >Back to Order</Link>
                <Link to="/create-request" >Add new</Link>
            </div>
        </div>
    )
}

export default Response