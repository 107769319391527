import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'
import Header from '../components/Header/Header';
import cursor from '../images/cursor.png'


const ProtectedRoutes = () => {
    const { modelAnnations } = useSelector((state) => state.modelViewer);
    const user = JSON.parse(localStorage.getItem("user"));

    return (
        user?.access
            ? <>
                <Header />
                <div className='main-content-wrapper' style={modelAnnations.addNew ? { cursor: `url(${cursor}), auto` } : { cursor: 'auto' }}>
                    <div className='main-content'>
                        <Outlet />
                    </div>
                </div>
            </>
            : <Navigate to='/login' />
    )
}

export default ProtectedRoutes