import { createSlice } from '@reduxjs/toolkit'
import { getRequestNotification } from '../actions/requestNotification'

const initialState = {
    loading: false,
    error: null,
    notification: {
        "need_data": false,
        "ready": false
    }
}

const requestNotificationSlice = createSlice({
    name: 'requestNotification',
    initialState,
    reducers: {
        changeNotifications: (state, { payload }) => {
            state.notification[payload] = false
        },
    },
    extraReducers: {
        [getRequestNotification.pending]: (state) => {
            state.loading = true
        },
        [getRequestNotification.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getRequestNotification.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.notification = payload
        },
    },
})

export const {
    changeNotifications
} = requestNotificationSlice.actions
export default requestNotificationSlice.reducer