import { createSlice } from '@reduxjs/toolkit'
import { getOrderNotification } from '../actions/orderNotification'

const initialState = {
    loading: false,
    error: null,
    notification: {
        "review": false
    }
}

const orderNotificationSlice = createSlice({
    name: 'orderNotification',
    initialState,
    reducers: {
        changeOrderNotifications: (state, { payload }) => {
            state.notification[payload] = false
        },
    },
    extraReducers: {
        [getOrderNotification.pending]: (state) => {
            state.loading = true
        },
        [getOrderNotification.rejected]: (state, { payload }) => {
            state.loading = false
            state.error = payload
        },
        [getOrderNotification.fulfilled]: (state, { payload }) => {
            state.loading = false
            state.notification = payload
        },
    },
})

export const {
    changeOrderNotifications
} = orderNotificationSlice.actions
export default orderNotificationSlice.reducer