import React from 'react'
import { Link } from 'react-router-dom'
import './ModelDetailsBreadcrumb.scss'

const ModelDetailsBreadcrumb = ({ children }) => {
    return (
        <div className='order-breadcrumb'>
            <ul>
                {
                    children.map((child, index) => (
                        <li key={index}>
                            <Link to={child.url}>
                                {child.name}
                            </Link>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default ModelDetailsBreadcrumb