import React from "react"
import ContentLoader from "react-content-loader"

const CheckoutSummarySkeleton = (props) => (
    <ContentLoader
        speed={2}
        width='100%'
        height='40px'
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="0" rx="4" ry="4" width="100%" height="35" />
    </ContentLoader>
)

export default CheckoutSummarySkeleton

